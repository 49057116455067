
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
import Calls_basic from '!babel-loader!@mdx-js/loader!../Calls_basic.mdx'
import Calls_basic_buyer_seller from '!babel-loader!@mdx-js/loader!../Calls_basic_buyer_seller.mdx'
import IntroPut from '!babel-loader!@mdx-js/loader!../IntroPut.mdx'
import Black_Scholes_Simulation from '!babel-loader!@mdx-js/loader!../Black_Scholes_Simulation.mdx'
import Volatility_Intro from '!babel-loader!@mdx-js/loader!../Volatility_Intro.mdx'
import CBDelta from '!babel-loader!@mdx-js/loader!../CBDelta.mdx'
import CSDelta from '!babel-loader!@mdx-js/loader!../CSDelta.mdx'
import LCDelta2D from '!babel-loader!@mdx-js/loader!../LCDelta2D.mdx'
import LCDelta2DSensitivity from '!babel-loader!@mdx-js/loader!../LCDelta2DSensitivity.mdx'
import LPDelta2D from '!babel-loader!@mdx-js/loader!../LPDelta2D.mdx'
import LCGamma3D from '!babel-loader!@mdx-js/loader!../LCGamma3D.mdx'
import CoveredCalls from '!babel-loader!@mdx-js/loader!../CoveredCalls.mdx'
import PoorMansCoveredCalls from '!babel-loader!@mdx-js/loader!../PoorMansCoveredCalls.mdx'
import WheelStrategy from '!babel-loader!@mdx-js/loader!../WheelStrategy.mdx'
import LCTheta3D from '!babel-loader!@mdx-js/loader!../LCTheta3D.mdx'
import LCTheta2D from '!babel-loader!@mdx-js/loader!../LCTheta2D.mdx'
import Collar from '!babel-loader!@mdx-js/loader!../Collar.mdx'
import Bullish_Call_Spread from '!babel-loader!@mdx-js/loader!../Bullish_Call_Spread.mdx'
import Volatility_Straddle from '!babel-loader!@mdx-js/loader!../Volatility_Straddle.mdx'
import Volatility_Strangle from '!babel-loader!@mdx-js/loader!../Volatility_Strangle.mdx'
import Bearish_Put_Ratio from '!babel-loader!@mdx-js/loader!../Bearish_Put_Ratio.mdx'
import Volatility_Iron_Condor from '!babel-loader!@mdx-js/loader!../Volatility_Iron_Condor.mdx'
import Iron_Butterfly from '!babel-loader!@mdx-js/loader!../Iron_Butterfly.mdx'
import Bearish_Long_Put_Calendar from '!babel-loader!@mdx-js/loader!../Bearish_Long_Put_Calendar.mdx'
import Diagonal_Spread_Credit_Puts from '!babel-loader!@mdx-js/loader!../Diagonal_Spread_Credit_Puts.mdx'
export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 900
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const links = [{
  title: "Google",
  url: ""
}, {
  title: "Facebook",
  url: ""
}, {
  title: "Twitter",
  url: ""
}];
export const question1 = {
  text: "What is the capital of France?",
  // image: "https://example.com/france.jpg",
  // video: "https://example.com/france.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const question2 = {
  text: "What is the capital of France?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  // video: "https://example.com/france.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const question3 = {
  text: "What is the capital of France?",
  // image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/short_call_margin_thumbnail.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const topics = [{
  title: 'Treasury Bills',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question1} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'REPO',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question3} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'FED Funds',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question2} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'FED Repo Market',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
       <div className="image-container" style={{
      position: 'absolute',
      top: '50px',
      bottom: '50px',
      left: '50px',
      right: '50px',
      padding: '20px'
    }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/bearish_call_spread/bearish_call_spread_thumb.png" style={{
        maxWidth: '100%',
        maxHeight: '100%'
      }} />
    </div>
      <SimpleDialog buttonPosition={{
      left: "40%",
      top: "40%"
    }} title={'Quiz'} mdxType="SimpleDialog">
            <Container mdxType="Container">
              <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/landscape/strategy_diag_8.json" ggbParameters={ggbParameters2} appId="appId1volquiz" email="pavanmirla@gmail.com" data={[{
          component: <Box2 mdxType="Box2" />,
          button: "longcall"
        }]} mdxType="GeogebraDraw" /> 
            </Container>
      </SimpleDialog>
      <ButtonMailto label="Email this" mailto="mailto:office@21ifm.com?subject=Note on SOFR chapter" top={"91%"} left={"88%"} mdxType="ButtonMailto" />
  </div>
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const GeogebraDraw = makeShortcode("GeogebraDraw");
const ImageCard = makeShortcode("ImageCard");
const SimpleDialog = makeShortcode("SimpleDialog");
const INT_RATES_FUTURES_101 = makeShortcode("INT_RATES_FUTURES_101");
const Container = makeShortcode("Container");
const Quiz = makeShortcode("Quiz");
const Box2 = makeShortcode("Box2");
const ButtonMailto = makeShortcode("ButtonMailto");
const layoutProps = {
  ggbParameters,
ggbParameters2,
opts,
Box1,
Text9,
Text14,
question1,
question2,
question3,
topics
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">














    <h1>{`OPTIONS 101`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/CASESTUDIES/options_strategies_db.json" ggbParameters={ggbParameters2} appId="appId1volquiz" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
    <div style={{
      position: 'relative'
    }}>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/MONEY_MARKETS_101/STAIRS_IMG.png" mdxType="ImageCard" />
    <SimpleDialog buttonPosition={{
        left: "56%",
        top: "85%"
      }} title={'CALLS 101'} text="1" mdxType="SimpleDialog">
          <Calls_basic mdxType="Calls_basic" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "56%",
        top: "80%"
      }} title={'CALLS BUYER AND SELLER. EXERCISE AND ASSIGNMENT 101'} text="2" mdxType="SimpleDialog">
         <Calls_basic_buyer_seller mdxType="Calls_basic_buyer_seller" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "56%",
        top: "75%"
      }} title={'PUTS 101'} text="3" mdxType="SimpleDialog">
    <IntroPut mdxType="IntroPut" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "50%",
        top: "71%"
      }} title={'BLACK SCHOLES OPTIONS PRICING 101'} text="4" mdxType="SimpleDialog">
    <Black_Scholes_Simulation mdxType="Black_Scholes_Simulation" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "50%",
        top: "66%"
      }} title={'IMPLIED VOLATILITY 101'} text="5" mdxType="SimpleDialog">
    <Volatility_Intro mdxType="Volatility_Intro" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "36%",
        top: "64%"
      }} title={'CALL DELTA 101'} text="6" list={links} mdxType="SimpleDialog">
    <LCDelta2D mdxType="LCDelta2D" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "43%",
        top: "63%"
      }} title={'PUT DELTA 101'} text="7" list={links} mdxType="SimpleDialog">
    <LPDelta2D mdxType="LPDelta2D" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "40%",
        top: "59%"
      }} title={'GAMMA 3D'} text="9" list={links} mdxType="SimpleDialog">
    <LCGamma3D mdxType="LCGamma3D" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "35%",
        top: "56%"
      }} title={'THETA 3D'} text="10" list={links} mdxType="SimpleDialog">
    <LCTheta3D mdxType="LCTheta3D" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "42%",
        top: "51%"
      }} title={'COVERED CALL '} text="11" list={links} mdxType="SimpleDialog">
    <CoveredCalls mdxType="CoveredCalls" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "47%",
        top: "54%"
      }} title={'COLLAR'} text="12" list={links} mdxType="SimpleDialog">
    <Collar mdxType="Collar" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "43%",
        top: "46%"
      }} title={'BULL CALL SPREAD'} text="13" list={links} mdxType="SimpleDialog">
    <Bullish_Call_Spread mdxType="Bullish_Call_Spread" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "52%",
        top: "49%"
      }} title={'SHORT STRADDLE'} text="14" list={links} mdxType="SimpleDialog">
    <Volatility_Straddle mdxType="Volatility_Straddle" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "50%",
        top: "43%"
      }} title={'SHORT STRANGLE'} text="15" list={links} mdxType="SimpleDialog">
    <Volatility_Strangle mdxType="Volatility_Strangle" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "55%",
        top: "41%"
      }} title={'BEARISH PUT RATIO'} text="16" list={links} mdxType="SimpleDialog">
    <Bearish_Put_Ratio mdxType="Bearish_Put_Ratio" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "61%",
        top: "31%"
      }} title={'SHORT CONDOR. SELL VOL'} text="17" list={links} mdxType="SimpleDialog">
    <Volatility_Iron_Condor mdxType="Volatility_Iron_Condor" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "65%",
        top: "35%"
      }} title={'IRON BUTTERFLY'} text="18" mdxType="SimpleDialog">
    <Iron_Butterfly mdxType="Iron_Butterfly" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "60%",
        top: "27%"
      }} title={'INTEREST RATE FUTURES '} text="19" list={links} mdxType="SimpleDialog">
    <INT_RATES_FUTURES_101 mdxType="INT_RATES_FUTURES_101" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "61%",
        top: "21%"
      }} title={'NEUTRAL CALENDAR SPREAD with PUTS'} text="20" mdxType="SimpleDialog">
    <Bearish_Long_Put_Calendar mdxType="Bearish_Long_Put_Calendar" />
    </SimpleDialog>
    <SimpleDialog buttonPosition={{
        left: "55%",
        top: "15%"
      }} title={'DIAGONAL CALENDAR SPREAD with PUTS'} text="20" mdxType="SimpleDialog">
    <Diagonal_Spread_Credit_Puts mdxType="Diagonal_Spread_Credit_Puts" />
    </SimpleDialog>
 </div>




    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;