import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

const VideoSection = ({video}) => {
  const theme = useTheme();
  return (
    <Box
    //   sx={{
    //     position: 'relative',
    //     display: 'flex',
    //     alignItems: 'center',
    //     '&::after': {
    //       content: '""',
    //       position: 'absolute',
    //       left: 50,
    //       right: 0,
    //       bottom: 100,
    //       top: 0,
    //       width: 1,
    //       height: 1,
    //       zIndex: 3,
    //       marginLeft: 100,
    //     //   background: theme.palette.secondary.main,
    //       opacity: 0.2,
    //     },
    //   }}
    >
      <Box
        component={'video'}
        width={.5}
        autoPlay={false}
        muted={false}
        loop={false}
        controls={true}
        sx={{
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                margin: 'auto'
        }}
      >
        <source
          src={video}
          type="video/mp4"
        />
        {/* <source
          src="https://assets.maccarianagency.com/videos/video.mp4"
          type="video/webm"
        />
        <source
          src="https://assets.maccarianagency.com/videos/video.mp4"
          type="video/ogg"
        /> */}
        Your browser do not support HTML5 video.
      </Box>
    </Box>
  );
};

export default VideoSection;
