
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 950
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const VideoSection = makeShortcode("VideoSection");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Box2 = makeShortcode("Box2");
const layoutProps = {
  ggbParameters,
ggbParameters2,
opts,
Box1,
Text9,
Text14
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <h1>{`THIS IS A PREVIEW`}</h1>
    <br />
    <br />
    <br />
    <br />
    <h1>{`Interest rate swaps 101`}</h1>
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/SWAPS/INT_RATE_SWAPS.mp4" mdxType="VideoSection" />
    <br />
    <br />
    <br />
    <br />
    <h1>{`Coming Up Next: An Interactive Guide`}</h1>
    <br />
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/IRS_INTERACTIVE_VIDEO.mp4" mdxType="VideoSection" />
    <br />
    <br />
    <br />
    <br />
    <br />
   <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/INT_RATE_SWAP_MAR_23_v2.json" ggbParameters={ggbParameters2} appId="risk_premium_basic" email="pavanmirla@gmail.com" data={[{
      component: <Box2 mdxType="Box2" />,
      button: "longcall"
    }]} mdxType="GeogebraDraw" /> 
                <br />
    <br />
    <br />
    <br />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;