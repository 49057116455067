
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 900
};
export const ggbParameters3 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: '100%',
  height: '100%'
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const VideoSection = makeShortcode("VideoSection");
const Container = makeShortcode("Container");
const layoutProps = {
  ggbParameters,
ggbParameters2,
ggbParameters3,
opts,
Box1,
Text9,
Text14
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">









    <br />
    <br />
    <br />
    <br />
    <h1>{`data from baha.com`}</h1>
    <h1>{`SOFR FUTURES AND OPTIONS DATA`}</h1>
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/DATA/SOFR_OPTIONS_FUTURES.mp4" mdxType="VideoSection" />
    <br />
    <br />
    <Container mdxType="Container">
    <a href="https://www.baha.com/search" target="_blank">Click here to search</a>
    </Container>
    <h1>{`EURODOLLAR DATA`}</h1>
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/DATA/EURODOLLAR.mp4" mdxType="VideoSection" />
    <h1>{`BARCHART DATA`}</h1>
    <h1>{`SOFR FUTURES AND OPTIONS`}</h1>
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/DATA/SOFR_BARCHART.mp4" mdxType="VideoSection" />
    <h1>{`ICE  BofAML MOVE Index`}</h1>
    <Container mdxType="Container">
    <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap'
      }}>
        <a href="https://markets.ft.com/data/indices/tearsheet/summary?s=MOVE:PSE">
            <img style={{
            maxWidth: '350px',
            maxHeight: '350px'
          }} src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/FUTURES/FORWARD_101/MOVE_INDEX.png" alt="Example" />
        </a>
          <a href="https://markets.ft.com/data/funds/tearsheet/risk?s=GUSTX">
        <img style={{
            maxWidth: '350px',
            maxHeight: '350px'
          }} src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/FUTURES/FORWARD_101/GMO_US_TREASURY.png" alt="Example" />
        </a>
    </div>
    </Container>
    <h1>{`LONG Government`}</h1>
    <a target="_blank" href="https://markets.ft.com/data/funds/tearsheet/summary?s=LGOV:PCQ:USD">First Trust Long Duration Opp ETF</a>
    <a target="_blank" href="https://markets.ft.com/data/funds/tearsheet/summary?s=IEF:NMQ:USD">iShares 7-10 Year Treasury Bond ETF</a>
    <a target="_blank" href="https://markets.ft.com/data/funds/tearsheet/summary?s=PLW:NMQ:USD">Invesco 1-30 Laddered Treasury ETF</a>
    <a target="_blank" href="https://markets.ft.com/data/funds/tearsheet/summary?s=TLH:PCQ:USD">iShares 10-20 Year Treasury Bond ETFF</a>
    <a target="_blank" href="https://markets.ft.com/data/funds/tearsheet/summary?s=DBLDX">DoubleLine Long Duration Total Ret Bd I</a>
    <a target="_blank" href="https://markets.ft.com/data/funds/tearsheet/summary?s=LGOV:PCQ:USD">First Trust Long Duration Opp ETF</a>
    <a target="_blank" href="https://markets.ft.com/data/funds/tearsheet/summary?s=LGOV:PCQ:USD">First Trust Long Duration Opp ETF</a>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;