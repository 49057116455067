
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import ButtonMailto from "../../components/ButtonMailto.js";
export const items1 = [{
  title: 'What is Margin and why is Margin required for stocks?',
  subtitle: "Margin is borrowing money from your broker to buy additional securities, using your cash and marginable securities as collateral. Interest is charged on the borrowed funds."
}, {
  title: 'What is Initial Margin requirement?',
  subtitle: 'Initial Margin is the Reg T equity requirement for purchasing marginable equities on margin. It is set at 50% - brokers can require more.'
}, {
  title: 'What is Maintenance Margin?',
  subtitle: "The Maintenance Margin is the minimum amount of equity that a trader must maintain in order to avoid being issued a margin call. It is 25% for long positions and 30% for short positions - brokers can require more."
}, {
  title: 'What is Margin call? ',
  subtitle: "If account equity falls below the Maintenance Requirement, the broker will either request more funds or close your positions."
}, {
  title: 'What are advantages of Margin Trading?',
  subtitle: 'Margin borrowing provides leverage, enabling a trader to trade larger positions than the amount of cash in the account.  '
}];
export const items2 = [{
  title: 'Maintenace requirement might depend on stock volatility',
  subtitle: 'Brokers may set a higher maintenance requirement if the credit risk is higher or the stock is excessively volatile'
}];
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const references = [{
  title: 'CBOW Margin Manual',
  href: "https://cdn.cboe.com/resources/options/margin_manual_april2000.pdf"
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const FlipCard = makeShortcode("FlipCard");
const References = makeShortcode("References");
const layoutProps = {
  items1,
items2,
ggbParameters,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Margin requirements`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Margin for Stocks"} mdxType="Content" />
    </Container>
    <h1>{`QUESTION 1`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/MARGINS/q1mcs/38_q1.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/margin_Put_seller/correct_answer_margin.png" mdxType="FlipCard" />
    <br />
    <br />
    <h1>{`QUESTION 2`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/MARGINS/q1mcs/q2_40.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/MARGINS/q1mcs/q2_b.png" mdxType="FlipCard" />
    <br />
    <br />
    <h1>{`Interactive`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/MARGINS/margin_stocks_13.json" ggbParameters={ggbParameters} appId="appId1margincall" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  <Content items={items2} title={"A few observations"} mdxType="Content" />
    </Container>


    <br />
    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;