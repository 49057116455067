
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 1000
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const question1 = {
  text: "Are SOFR Term Rate and SOFR Index the same?",
  // image: "https://example.com/france.jpg",
  // video: "https://example.com/france.mp4",
  answers: ["No", "Yes"],
  correctAnswer: "No"
};
export const question2 = {
  text: "What contract unit for 1M SOFR Futures contract?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/QUESTION_PHILANTROPHY/phil_quiz_2.png",
  // video: "https://example.com/france.mp4",
  answers: ["$41.67", "$25", "$21"],
  correctAnswer: "$41.67"
};
export const question3 = {
  text: "What is the capital of France?",
  // image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/short_call_margin_thumbnail.mp4",
  answers: ["$41.", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const topics = [//    {
  //     title: 'REPO',
  //     component: () => (
  //  <div style={{ position: 'absolute', width: '100%',  height: '100%' , margin: '20px'}}>
  //           <Container>
  //             <Quiz question={question3}/>
  //              {/* <Quiz question={question2}/> */}
  //           </Container>
  //   </div>
  //     )
  //   }
{
  title: 'SOFR Index',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question1} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
},, {
  title: 'Futures Contract unit',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question2} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
} //    {
//     title: 'FED Repo Market',
//     component: () => (
//   <div style={{ position: 'relative', minHeight: '75vh',maxHeight: '80vh'}}>
//        <div className="image-container" style={{ position: 'absolute', top: '50px', bottom: '50px', left: '50px', right: '50px', padding: '20px' }}>
//         <img
//             src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/bearish_call_spread/bearish_call_spread_thumb.png"
//             style={{ maxWidth: '100%', maxHeight: '100%' }}
//         />
//     </div>
//       <SimpleDialog buttonPosition={{left: "40%", top: "40%"}} title={'Quiz'}>
//             <Container>
//               <GeogebraDraw
//                   jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/landscape/strategy_diag_8.json"
//                   ggbParameters={ggbParameters2}
//                   appId="appId1volquiz"
//                   email="pavanmirla@gmail.com"
//                   data={[
//                   {component: <Box2/>, button:  "longcall"}
//                   ]}
//                 /> 
//             </Container>
//       </SimpleDialog>
//       <ButtonMailto label="Email this" mailto="mailto:office@21ifm.com?subject=Note on SOFR chapter" top= {"91%"} left={"88%"}/>
//   </div>
//     )
//   },
];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Container = makeShortcode("Container");
const ImageAnnotation = makeShortcode("ImageAnnotation");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Box2 = makeShortcode("Box2");
const LandmarkButton = makeShortcode("LandmarkButton");
const BlinkingButton = makeShortcode("BlinkingButton");
const MyCarousel = makeShortcode("MyCarousel");
const Quiz = makeShortcode("Quiz");
const layoutProps = {
  ggbParameters,
ggbParameters2,
opts,
Box1,
Text9,
Text14,
question1,
question2,
question3,
topics
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <h1>{`LOCK interest rates`}</h1>
  <Container mdxType="Container">
  <ImageAnnotation mdxType="ImageAnnotation">
          <>
            <div style={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}>
              <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/QUESTION_PHILANTROPHY/lock.png" style={{
              width: '100%',
              height: 'auto'
            }} />
            </div>
          </>
 </ImageAnnotation>
 </Container>
    <h1>{`Interactive. Time line of SOFR Futures trade`}</h1>
   <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/CASESTUDIES/TEN_YEAR_TREASURY_FUT/philantrophy_lock_rates_2.json" ggbParameters={ggbParameters2} appId="appIPhilanOut" email="pavanmirla@gmail.com" data={[{
      component: <Box2 mdxType="Box2" />,
      button: "longcall"
    }]} mdxType="GeogebraDraw" /> 
    {
      /* https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/QUESTION_PHILANTROPHY/Risk_office_.png */
    }
    {
      /* 
       <Container>
       <ImageAnnotation>
               <>
                 <div style={{ position: 'relative', width: '70%' , height: '70%', margin : 'auto'}}>
                   <img
                     src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/QUESTION_PHILANTROPHY/intro_letter_philantrophy.png"
                     style={{ width: '100%', height: 'auto' }}
                   />
                   <LandmarkButton
                     id={1}
                     x={3}
                     y={51}
                     text="As you may be aware, interest rates have been fluctuating recently, and while the current three-month Term SOFR reference rate is 4.81% (annualized), I believe that it is likely to fall in the near future. As such, I have been considering ways to mitigate the potential impact of this interest rate risk on the foundation's investments."
                   />
                 </div>
               </>
      </ImageAnnotation>
      </Container>
      */
    }
  <Container mdxType="Container">
  <ImageAnnotation mdxType="ImageAnnotation">
          <>
            <div style={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}>
              <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/QUESTION_PHILANTROPHY/philantropy_lunch_and_learn_1.png" style={{
              width: '100%',
              height: 'auto'
            }} />
              <LandmarkButton id={1} x={64} y={61} text="The value of one basis point for a 3 month SOFR futures contract is typically $25 per contract. So, if the interest rate changes by one basis point from the time the contract is signed to the delivery date, the holder of the contract can expect to make or lose $25." mdxType="LandmarkButton" />
            <BlinkingButton buttonPosition={{
              left: "65%",
              top: "40%"
            }} text={"1"} url={"https://www.cmegroup.com/education/files/sofr-futures-contract-specifications.pdf"} mdxType="BlinkingButton" />
            </div>
          </>
 </ImageAnnotation>
 </Container> 
  <Container mdxType="Container">
  <ImageAnnotation mdxType="ImageAnnotation">
          <>
            <div style={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}>
              <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/QUESTION_PHILANTROPHY/philantropy_lunch_and_learn_2.png" style={{
              width: '100%',
              height: 'auto'
            }} />
                <LandmarkButton id={1} x={69} y={31} text="No, the SOFR rate is a broad measure of the cost of borrowing cash overnight secured by Treasury collateral, while the SOFR term rate is a forward-looking term rate derived from SOFR futures contracts." mdxType="LandmarkButton" />
            <BlinkingButton buttonPosition={{
              left: "55%",
              top: "55%"
            }} text={"1"} url={"https://www.cmegroup.com/market-data/cme-group-benchmark-administration/term-sofr.html"} mdxType="BlinkingButton" />
            <BlinkingButton buttonPosition={{
              left: "65%",
              top: "60%"
            }} text={"2"} url={"https://www.cmegroup.com/markets/interest-rates.html#products"} mdxType="BlinkingButton" />
            <BlinkingButton buttonPosition={{
              left: "65%",
              top: "78%"
            }} text={"3"} url={"https://www.cmegroup.com/education/courses/introduction-to-futures/understanding-contract-trading-codes.html"} mdxType="BlinkingButton" />
            </div>
          </>
 </ImageAnnotation>
 </Container>




    <h1>{`Quiz`}</h1>
    <Container mdxType="Container">
  <div>
   <MyCarousel topics={topics} mdxType="MyCarousel" />
  </div>
    </Container>
    {
      /* <div style={{ position: 'absolute',  left: "25%", top:'1%' }}>
        <Marker left="25%" top="5%"  mleft="50%" mtop="25%"   text="1" >
               <Box1/> 
             </Marker>
      </div> */
    }
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;