
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  width: "400px",
  height: '400px',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const question1 = {
  text: "What is the capital of France?",
  // image: "https://example.com/france.jpg",
  // video: "https://example.com/france.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const question2 = {
  text: "What is the capital of France?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  // video: "https://example.com/france.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const question3 = {
  text: "What is the capital of France?",
  // image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/short_call_margin_thumbnail.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const topics = [{
  title: 'Efective rates',
  component: () => <div style={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    margin: '20px'
  }}>
          <Container mdxType="Container">
            <Quiz question={question1} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'Nominal vs Real Rates',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question3} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'Compounding frequency',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question2} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'Discount rates',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
        <Container mdxType="Container"> 
            <Quiz question={question2} mdxType="Quiz" />
        </Container>
  </div>
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const VideoSection = makeShortcode("VideoSection");
const Container = makeShortcode("Container");
const ImageAnnotation = makeShortcode("ImageAnnotation");
const LandmarkButton = makeShortcode("LandmarkButton");
const ImageCard = makeShortcode("ImageCard");
const MyCarousel = makeShortcode("MyCarousel");
const Quiz = makeShortcode("Quiz");
const layoutProps = {
  ggbParameters,
ggbParameters2,
opts,
Box1,
Text9,
Text14,
question1,
question2,
question3,
topics
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <br />
    <br />
    <br />
    <h1>{`SPOT RATES 101`}</h1>
    <br />
    <br />
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/INT_RATES_101/FORWARD_SPOT_RATES_2.mp4" mdxType="VideoSection" />
    <br />
    <br />
    <br />
    <h1>{`FORWARD RATES 101`}</h1>
    <br />
    <br />
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/INT_RATES_101/FORWARD_SPOT_RATES_1.mp4" mdxType="VideoSection" />
    <br />
    <br />
    <br />
    <h1>{`TERM STRUCTURE 101`}</h1>
    <br />
    <br />
    <br />
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/INT_RATES_101/TERM_STRUCTURE.mp4" mdxType="VideoSection" />
    <br />
    <br />
    <br />
    <h1>{`time value of money 101`}</h1>
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/INT_RATES_101/TIME_VALUE_OF_MONEY_1.mp4" mdxType="VideoSection" />
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/INT_RATES_101/TIME_VALUE_OF_MONEY_2.mp4" mdxType="VideoSection" />
    <br />
    <br />
    <br />
    <br />
    <h1>{`Compounding 101`}</h1>
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/INT_RATES_101/COMPOUNDING.mp4" mdxType="VideoSection" />
    <br />
    <br />
    <br />
    <br />
    <h1>{`Annualization 101`}</h1>
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/INT_RATES_101/ANNUALIZATION.mp4" mdxType="VideoSection" />
    <br />
    <br />
    <br />
    <br />
  <Container mdxType="Container">
  <ImageAnnotation mdxType="ImageAnnotation">
          <>
            <div style={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}>
              <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/INT_RATES_101/V2/interest_rates_101_1.png" style={{
              width: '100%',
              height: 'auto'
            }} />
              <LandmarkButton id={1} x={14} y={9} text="Interest rate is the percentage at which interest is paid by borrowers for the use of money they borrow from lenders. The interest rate is usually determined by the market forces of supply and demand for money" mdxType="LandmarkButton" />
              <LandmarkButton id={2} x={35} y={22} text="Deposit or spot rates are a type of short-term rates that depend on the length of time money is left with the bank, with longer terms typically offering higher rates" mdxType="LandmarkButton" />
                <LandmarkButton id={3} x={40} y={63} text="The term structure of interest rates, also known as the yield curve, is a graph that shows the relationship between interest rates and the maturity of bonds/loan" mdxType="LandmarkButton" />
               <LandmarkButton id={4} x={40} y={79} text="A forward interest rate is the rate at which a financial institution or a bank is willing to lend or borrow money at a specific point in the future. They are used to hedge against changes in interest rates and are often used by large corporations and financial institutions to manage their financial risk " mdxType="LandmarkButton" />
            </div>
          </>
 </ImageAnnotation>
 </Container>
  <Container mdxType="Container">
  <ImageAnnotation mdxType="ImageAnnotation">
          <>
            <div style={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}>
              <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/INT_RATES_101/V2/interest_rates_101_2.png" style={{
              width: '100%',
              height: 'auto'
            }} />
              <LandmarkButton id={1} x={35} y={5} text="In order to compare different cash flow sequences, the ability to shift money back and forth is time is necessary" mdxType="LandmarkButton" />
              <LandmarkButton id={2} x={35} y={24} text="When the investment spans a long time interval, interest is often paid periodically along the way and not only at the end of the time horizon. This is called compounding interest, where the interest received is reinvested to earn interest on interest" mdxType="LandmarkButton" />
                <LandmarkButton id={3} x={36} y={46} text="Interest rates are quoted in annual terms to allow for comparison across different maturities. The quoted rate is called the annual percentage rate.
                The effective annual rate is the true annual interest rate that accounts for compounding frequency, taking into consideration the impact of compounding on the overall interest earned" mdxType="LandmarkButton" />
               <LandmarkButton id={4} x={36} y={65} text="The process of discounting is used to calculate the present value of future cash flows by dividing it by a growth factor, ( 1+ r), raised to the power n. Where r is the interest rate and n is the number of compounding periods" mdxType="LandmarkButton" />
                <LandmarkButton id={5} x={36} y={85} text="Nominal interest rates are the stated or quoted rates, While, the real interest rates are the nominal rates adjusted for inflation" mdxType="LandmarkButton" />
            </div>
          </>
 </ImageAnnotation>
 </Container>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/INT_RATES_101/V2/interest_rates_101_3.png" mdxType="ImageCard" />




    <Container mdxType="Container">
  <div>
   <MyCarousel topics={topics} mdxType="MyCarousel" />
  </div>
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;