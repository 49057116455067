
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import { useEffect, useState } from "react";
import SimpleAccordion from "../../components/SimpleAccordian";
import IfPaidUser from "../../components/IfPaidUser";
import useFetchData from "../../components/UseFetchData";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Papa from "papaparse";
import ReactPlayerComp from "../../components/ReactPlayerComp";
import GRCard from "../../components/GRCard";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
export const data = [{
  question: "one",
  answer: "answer"
}, {
  question: "one",
  answer: "answer"
}];
export const items1 = [{
  title: 'Black Scholes formula: Logic',
  subtitle: 'The Black Scholes equation results in a curved relationship between the call premium and the price of the underlying.Black Scholes assumes that the portfolio is delta neutral. This is a long call position and certain units of stocks such that the portfolio is unaffected by stock price changes. Probability of exercise of the option is estimated. Conditional on that the delta neutral posiiton is closed. This results in option payoff formula. Details are in the chart below.'
}, {
  title: 'Influence of Time to expiry on option premium',
  subtitle: 'When the time to maturity is longer, the expected return is higher. Therefore, the buyer is willing to pay more for more maturity calls..'
}, {
  title: 'Influence of strike price on option premium',
  subtitle: 'The strike price. If S/K ratio is high, the buyer is willing to pay more for the option.'
}, {
  title: 'Influence of volatility on option premium',
  subtitle: 'If volatility is high, there is a greater chance of a very high stock price and, therefore, a higher payout for the call.'
}];
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  backgroundColor: 'tomato'
}}>
    <ReactPlayer url='https://storage.googleapis.com/chapters-ifm/testfolder/videos/Theta_video_drive.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  backgroundColor: 'tomato'
}}>
    <ReactPlayerComp url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayerComp" />
  </div>;
export const ggbParameters = {
  // material_id: "",
  width: 1950,
  height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  data,
items1,
Box1,
Text9,
Text14,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Black Scholes Option pricing`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/black_scholes/sinclair_image.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/black_scholes/story_bs_intro.png
" mdxType="ImageCard" />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/black_scholes/story_bs_activity.png
" mdxType="ImageCard" />


    <Container mdxType="Container">
  {
        /* <Content items={items1} title={"Basics"}/> */
      }
  {
        /* <ContentTextBoxes/> */
      }
   {
        /* <Content items={items}/> */
      }
    </Container>
    <h1>{`INTERACTIVE`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/black_scholes/black_scholes_sep-2022_3.json" ggbParameters={ggbParameters} appId="ito" email="pavanmirla@gmail.com" data={[{
      component: <Text9 mdxType="Text9" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }]} mdxType="GeogebraDraw" /> 
    {
      /* dont delete */
    }
    {
      /* "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/black_scholes/black_scholes_ito_4.json" */
    }
    {
      /* https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/black_scholes_problem_app_10.json */
    }
    {
      /* https://storage.googleapis.com/chapters-ifm/testfolder/basics/black_scholes_illustration_with_density.json */
    }
    <h1>{`INTERACTIVE`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/black_scholes/black_scholes_sep-2022_put.json" ggbParameters={ggbParameters} appId="in_put" email="pavanmirla@gmail.com" data={[{
      component: <Text9 mdxType="Text9" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }]} mdxType="GeogebraDraw" /> 




    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;