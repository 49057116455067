/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import { colors } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import ggb_data from '../data/tulip_spot_trade.json';
import GeogebraComponent from "./GeogebraComponent";
import { Carousel } from 'react-responsive-carousel';
import ReactPlayer from 'react-player'


import "./NikeStyles.css";

export const ggb_hex = ggb_data.data
export const ggb_params = {
  // material_id: "",
  width: "100%",
  height: "100%",
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  ggbBase64: ggb_hex,
};

const CaseStudy2 = ({blogCards, title, description , image}) => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const imagesList = image.split(',')
  return (
    <Box
      padding={{ xs: 2, sm: 4 }}
      borderRadius={2}
      bgcolor={
          "#373737"
        // theme.palette.mode === 'dark' ? colors.red[50] : colors.red[900]
      }
      data-aos={'fade-up'}
    >
      <Grid
        container
        spacing={isMd ? 2 : 2}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Grid item xs={12} md={3}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={{ xs: 'flex-start', md: 'space-between' }}
            height={1}
          >
            <Box>
              <Typography variant={'h5'} fontWeight={700} gutterBottom color={"#90caf9"}>
                {title}
              </Typography>
              <Typography
                color={ "#90caf9"
                //   theme.palette.mode === 'dark'
                //     ? 'text.secondary'
                //     : 'text.primary'
                }
              >
            {/* {description} */}
              </Typography>
            </Box>
           
          </Box>
        </Grid>
        <Grid item xs={12} md={8} >
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid
              item
              xs={12}
              sx={{
                '& .lazy-load-image-background.lazy-load-image-loaded': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >

               {image.includes("mp4") &&
                    <div>
                    <ReactPlayer
                      url={image}
                      // playing
                      controls
                      config={{ file: { attributes: { id: 'audio-element' }}}}
                      width='100%'
                      height='100%'
                    />
                  </div>
                }

              {image !="none" && !image.includes("mp4") &&
              <Carousel width="100%" 
              showThumbs={true}
              showStatus={true}
              infiniteLoop
              // emulateTouch
              autoPlay
              interval={7000}
              dynamicHeight={false}
              useKeyboardArrows
              transitionTime={1500}>
             
            {imagesList.map((in_img, i) => (
              <div className="slide-holder">
                {in_img.includes("jpg")  &&
                <img src={in_img} alt={`${i}`}  />
                }
                { in_img.includes("png")  &&
                <img src={in_img} alt={`${i}`}  />
                }
                { in_img.includes("gif")  &&
                <img src={in_img} alt={`${i}`}  />
                }
              </div>
            ))}

        
              </Carousel>
             }

            </Grid>
            <Grid item xs={12}>
                   <Stack direction="row" justifyContent="end">
                   {blogCards}
                   </Stack>
                  
            </Grid>
      
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudy2;
