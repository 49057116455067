
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import ButtonMailto from "../../components/ButtonMailto.js";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
import HighlightSpeak from "../../components/HighlightSpeak";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1900,
  height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: "Neutral price forecast",
  subtitle: "In order to normalize data, it is useful to calculate returns in percentage terms. Each variable may start at a different price point. Retun is a key characteristic of most statistical techniques"
}, {
  title: "Same option types but different expiry dates. Calls or Puts?",
  subtitle: "In order to normalize data, it is useful to calculate returns in percentage terms. Each variable may start at a different price point. Retun is a key characteristic of most statistical techniques"
}, {
  title: 'Strategy benefits from time decay and low Implied Volatility',
  subtitle: "log-normal returns are frequently used in finance due to their statistical properties and computational efficiency. A product of two normally distributed variables is not normal, while a sum of normally distributed variables is normal. Rather than multiplying, logarithms replace multiplication with addition. Remember the basic log normal formula, where the log of the product of two variables equals the sum of the logs of each variable.  "
}, {
  title: 'Limited loss and profits',
  subtitle: "log-normal returns are frequently used in finance due to their statistical properties and computational efficiency. A product of two normally distributed variables is not normal, while a sum of normally distributed variables is normal. Rather than multiplying, logarithms replace multiplication with addition. Remember the basic log normal formula, where the log of the product of two variables equals the sum of the logs of each variable.  "
}, {
  title: 'Low cost of entry',
  subtitle: "log-normal returns are frequently used in finance due to their statistical properties and computational efficiency. A product of two normally distributed variables is not normal, while a sum of normally distributed variables is normal. Rather than multiplying, logarithms replace multiplication with addition. Remember the basic log normal formula, where the log of the product of two variables equals the sum of the logs of each variable.  "
}, {
  title: 'Effect of volatility of long dated and short dated options',
  subtitle: "log-normal returns are frequently used in finance due to their statistical properties and computational efficiency. A product of two normally distributed variables is not normal, while a sum of normally distributed variables is normal. Rather than multiplying, logarithms replace multiplication with addition. Remember the basic log normal formula, where the log of the product of two variables equals the sum of the logs of each variable.  "
}, {
  title: 'Net positive vega trade',
  subtitle: "log-normal returns are frequently used in finance due to their statistical properties and computational efficiency. A product of two normally distributed variables is not normal, while a sum of normally distributed variables is normal. Rather than multiplying, logarithms replace multiplication with addition. Remember the basic log normal formula, where the log of the product of two variables equals the sum of the logs of each variable.  "
}];
export const items2 = [{
  title: "Why use returns?",
  subtitle: "In order to normalize data, it is useful to calculate returns in percentage terms. Each variable may start at a different price point. Retun is a key characteristic of most statistical techniques"
}, {
  title: 'Why to use log returns?',
  subtitle: "log-normal returns are frequently used in finance due to their statistical properties and computational efficiency. A product of two normally distributed variables is not normal, while a sum of normally distributed variables is normal. Rather than multiplying, logarithms replace multiplication with addition. Remember the basic log normal formula, where the log of the product of two variables equals the sum of the logs of each variable.  "
}];
export const references = [{
  title: 'What is Time spread? ',
  href: "https://www.fidelity.com/learning-center/investment-products/options/options-strategy-guide/short-calendar-spread-puts"
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const References = makeShortcode("References");
const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Intro,
Text4,
indexArray,
items1,
items2,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">









    <h1>{`Neutral Calendar Spread with Puts`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/2022/STRATEGIES/debit_calendar_spread_f.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>
    <p>{`Tarders bet on this strategy expecting near term stocks to not move`}</p>
    <p>{`Trader will benefit if implied volatility is higher while stock moves a little`}</p>
    <p>{`Trader also benefits from passing time. See more details in the app below`}</p>
    <br />

    <Container mdxType="Container">
  {
        /* <Content items={items1} title={"Basics"}/> */
      }
    </Container>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/SPREADS/spreads_template_calendar_puts_debit.json" ggbParameters={ggbParameters} appId="id3" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "text11"
    }, {
      component: <Text4 mdxType="Text4" />,
      button: "text3"
    }]} mdxType="GeogebraDraw" />
    {
      /* jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/calendar-spread_put_2022.json" */
    }

    <Container mdxType="Container">
  {
        /* <Content items={items2} title={"Key take aways"}/> */
      }
    </Container>

    <br />
    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;