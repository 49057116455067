import React from 'react'
import { useState } from 'react'
import './styles.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { Document, Page, pdfjs } from 'react-pdf'
import styled from 'styled-components'
import { useWindowWidth } from '@wojtekmaj/react-hooks';

import useWindowDimensions from  '../UseWindowDimensions.js'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

const PDFDocumentWrapper = styled.div`
  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;

export default function LoadPdf(props) {
    const [scale, setScale] = useState('1')
    const width = useWindowWidth();
    const [pageNumber,SetPageNumber] = useState(1)
    const [totalPages,SetTotalPages] = useState(null)
    const [numPages, setNumPages] = useState(null);

    // const { height, width } = useWindowDimensions();
    let scaleNumber = parseFloat(scale)
    if (Number.isNaN(scaleNumber)) scaleNumber = 1

    function onDocumentLoadSuccess({ numPages }) {
      setNumPages(numPages);
    }

    return (
        <div style={{display: "table", width: "fit-content"}}>

            <PDFDocumentWrapper>
                <Document  file={props.file}  onLoadSuccess={onDocumentLoadSuccess}  loading ={"please wait"}    
                >
                <Page scale={.5} 
                      pageNumber={1}
                      width={width} // width: 90vw; max-width: 400px
                />
                </Document>
            </PDFDocumentWrapper>
        </div>
    )
}


// https://codesandbox.io/s/react-pdf-prevent-flash-with-scale-nse51l?file=/src/App.js