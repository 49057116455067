
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  width: "1950",
  height: '950',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Container = makeShortcode("Container");
const ImageAnnotation = makeShortcode("ImageAnnotation");
const LandmarkButton = makeShortcode("LandmarkButton");
const layoutProps = {
  ggbParameters,
ggbParameters2,
opts,
Box1,
Text9,
Text14
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <br />
    <br />
    <br />
    <h1>{`REPO`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/MONEY_MARKETS_101/REPPO_EXAMPLE.json" ggbParameters={ggbParameters2} appId="cc4_newer_version" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" />
    <br />
    <br />
  <Container mdxType="Container">
  <ImageAnnotation mdxType="ImageAnnotation">
          <>
            <div style={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}>
              <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/MONEY_MARKETS_101/Repo%20transactions.png" style={{
              width: '100%',
              height: 'auto'
            }} />
              <LandmarkButton id={1} x={35} y={5} text="GC repo is a type of collateralized lending where cash is borrowed by selling government securities and iusing them as collateral." mdxType="LandmarkButton" />
              <LandmarkButton id={2} x={35} y={14} text="Money market repo is widely used in the money market as a way for banks, money market funds, and other financial institutions to borrow and lend cash overnight or for short periods of time" mdxType="LandmarkButton" />
                <LandmarkButton id={3} x={66} y={14} text="The repo market is an important source of short-term funding for financial institutions and plays a key role in the overall functioning of the financial system" mdxType="LandmarkButton" />
            </div>
          </>
 </ImageAnnotation>
 </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;