
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 1000
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const question1 = {
  text: "How do investors earn a return on T-bills?",
  // image: "https://example.com/france.jpg",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/MONEY_MARKETS_101/t-bills_discount_basis.mp4",
  answers: ["a) By receiving interest payments periodically", "b) By selling the T-bills at a higher price than the purchase price", "c) By the difference between the discounted purchase price and the face value of the bill"],
  correctAnswer: "c) By the difference between the discounted purchase price and the face value of the bill"
};
export const question2 = {
  text: "How do investors earn a return on T-bills?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  // video: "https://example.com/france.mp4",
  answers: ["a) By receiving interest payments periodically", "b) By selling the T-bills at a higher price than the purchase price", "c) By the difference between the discounted purchase price and the face value of the bill"],
  correctAnswer: "c) By the difference between the discounted purchase price and the face value of the bill"
};
export const question3 = {
  text: "What is the capital of France?",
  // image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/short_call_margin_thumbnail.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const topics = [{
  title: 'Treasury Bills',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question1} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
                {
        /* <SimpleDialog buttonPosition={{left: "80%", top: "30%"}} title={"T-Bill explainer"} text={"1"}> */
      }
              {
        /* <GeogebraDraw
           jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/interest_rates/discount_factor.json"
           ggbParameters={ggbParameters2}
           appId="appId1volquiz"
           email="pavanmirla@gmail.com"
           data={[
           {component: <Box2/>, button:  "longcall"}
           ]}
         /> 
        </SimpleDialog> */
      }
      </Container>
  </div>
}, {
  title: 'T-BILL Yield Explainer',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
       <div className="image-container" style={{
      position: 'absolute',
      top: '50px',
      bottom: '50px',
      left: '50px',
      right: '50px',
      padding: '20px'
    }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/MONEY_MARKETS_101/tbill-intercative.png" style={{
        maxWidth: '100%',
        maxHeight: '100%'
      }} />
    </div>
      <SimpleDialog buttonPosition={{
      left: "60%",
      top: "70%"
    }} title={"T-Bill explainer"} text={"1"} mdxType="SimpleDialog">
            <Container mdxType="Container">
              <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/interest_rates/discount_factor.json" ggbParameters={ggbParameters2} appId="appId1volquiz" email="pavanmirla@gmail.com" data={[{
          component: <Box2 mdxType="Box2" />,
          button: "longcall"
        }]} mdxType="GeogebraDraw" /> 
            </Container>
      </SimpleDialog>
      <ButtonMailto label="Email this" mailto="mailto:office@21ifm.com?subject=Note on SOFR chapter" top={"91%"} left={"88%"} mdxType="ButtonMailto" />
  </div>
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const VideoSection = makeShortcode("VideoSection");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Container = makeShortcode("Container");
const Quiz = makeShortcode("Quiz");
const SimpleDialog = makeShortcode("SimpleDialog");
const Box2 = makeShortcode("Box2");
const ButtonMailto = makeShortcode("ButtonMailto");
const layoutProps = {
  ggbParameters,
ggbParameters2,
opts,
Box1,
Text9,
Text14,
question1,
question2,
question3,
topics
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <br />
    <br />
    <br />
    <h1>{`LIBOR 101`}</h1>
    <br />
    <br />
    <br />
 <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/INT_RATES_101/LIBOR_FAQ.mp4" mdxType="VideoSection" /> 
    <br />
    <br />
    <br />
    <h1>{`LIBOR LIFETIME`}</h1>
   <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/FED_101/LIBOR_101.json" ggbParameters={ggbParameters2} appId="money_market_yields" email="pavanmirla@gmail.com" mdxType="GeogebraDraw" /> 
    <br />
    <br />
    <br />
    <h1>{`Transition to LIBOR`}</h1>
    <br />
    <br />
    <br />
  <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/INT_RATES_101/LIBOR_FAQ_2.mp4" mdxType="VideoSection" /> 
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1>{`US. CENTRAL BANK's response to 2008 credit crisis`}</h1>
    <br />
  <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/FED_101/CENTRAL_BANKS_1.mp4" mdxType="VideoSection" /> 
    <br />
    <br />
    <br />
    <br />




    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;