import "./style.css";
import Marker from './ImageMarkers';
import React from 'react';


export default function ImageAnnotation (props) {
  return (
     <div  style= {{
      margin: 'auto',
      width: '85%',
      height: '100%',
      // border: '3px solid green',
      // padding: '10px'
     }}>
       {props.children}
    </div>
  );
}
