import React, { useState, useContext, useEffect } from "react";
import firebase from "firebase";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { firebaseAuth } from "./provider/AuthProvider";

import Heading from "./components/Heading";

import SignUp from "./components/SignUp";
import Payment from "./components/Payment";
import VideoSection from "./components/VideoSection";

import SignInMagicLink from "./components/SignInMagicLink";
import MagicLinkWait from "./components/MagicLinkWait";
import SignIn from "./components/SignIn";
import NotFound from "./components/NotFound";
import LinkList from "./components/LinkList";

import SignInEmail from "./components/SignInEmail";
import SignInPlusPurchase from "./components/SignInPlusPurchase";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import ForgotPassword from "./components/ForgotPassword";
import GeogebraDraw from "./components/GeogebraDraw";

import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import Demo from "./components/SimpleVideoCard";
import ImageCard from "./components/ImageCard";
import OverviewFlow from "./components/OverviewFlow";
import GridList from "./components/GridList";
import { Helmet, HelmetProvider } from "react-helmet-async";
import MessageModal from "./components/MessageModal";
import ButtonMailto from "./components/ButtonMailto.js";
import SingleCard from "./components/SingleCard.js";
import LoadPdf from "./components/Pdf/LoadPdf";
import Container from "./components/Layouts/Container";


import WhoAmI from "./components/WhoAmI.js";
import Sample from "./components/Pdf/Sample.jsx";
// import InLine from "./components/InLine";
// const About = lazy(() => importMDX('./pages/About.mdx'));
import { Portal } from "react-portal";
import YouTube from "react-youtube";

import CardsForBlogs from "./CardsForBlogs";
import AppHeader from "./components/AppHeader";
import SignUpLicense from "./components/SignUpLicense";
import AreaMap1 from "./components/AreaMap1";
import AreaMap2 from "./components/AreaMap2";
import Login from "./components/Login.tsx";
import { createCheckoutSession } from "./stripe/createCheckoutSession";
import usePremiumStatus from "./stripe/usePremiumStatus";
import isPaidUserTest from "./stripe/isPaidUserTest";
import useLocalStorage from "./firebase/uselocalstorage";
import { Redirect, useLocation } from "react-router-dom";
import Link from "@mui/material/Link";
import { useHistory } from "react-router-dom";

import OpenVideo from "./components/OpenVideo";
import BlinkingButton from "./components/BlinkingButton";
import SimpleDialog from "./components/SimpleDialog";
import ReactPlayer from "react-player";

import OpenPopup from "./components/OpenPopup";

// stage1

import SPOT_RATES_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/SPOT_RATES_101.mdx'
import FORWARD_RATES_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/FORWARD_RATES_101.mdx'
import TERM_STRUCTURE_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/TERM_STRUCTURE_101.mdx'
import TVM_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/TVM_101.mdx'
import COMPOUNDING_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/COMPOUNDING_101.mdx'
import ANNUALIZATION_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/ANNUALIZATION_101.mdx'
import LIBOR_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/LIBOR_101.mdx'
import SOFR from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/SOFR.mdx'

//stage2
import FED_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/FED_101.mdx'
import TREASURY_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/TREASURY_101.mdx'


//stage3
import BONDS_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/BONDS_101.mdx'
import BONDS_101_YTM from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/BONDS_101_YTM.mdx'
import DURATION from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/DURATION.mdx'
import CONVEXITY from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/CONVEXITY.mdx'



import INT_RATES_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/INT_RATES_101.mdx'
import MONEY_MARKETS_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/MONEY_MARKETS_101.mdx'

import BONDS_RISKS from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/BONDS_RISKS.mdx'


import FUTURES_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/FUTURES_101.mdx'
import OPTIONS_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/OPTIONS_101.mdx'
import SOFR_FUTURES_OPTIONS from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/SOFR_FUTURES_OPTIONS.mdx'
import PHILANTROPHY_SOFR from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/PHILANTROPHY_SOFR.mdx'
import FUTURES_OPTIONS_DATA_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/FUTURES_OPTIONS_DATA_101.mdx'
import EARTH_QUAKE_LOG from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/EARTH_QUAKE_LOG.mdx'
import SOFR_TERM_RATE from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/SOFR_TERM_RATE.mdx'
import INT_RATES_FUTURES_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/INT_RATES_FUTURES_101.mdx'
import INT_RATES_FUTURES_OPTIONS from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/INT_RATES_FUTURES_OPTIONS.mdx'
import DERIVATIVES_DATA from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/DERIVATIVES_DATA.mdx'
import BOND_FUNDS from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/BOND_FUNDS.mdx'
import CDX from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/CDX.mdx'
import TNOTE_FUTURES from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/TNOTE_FUTURES.mdx'
import SOFR_FUTURES from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/SOFR_FUTURES.mdx'
import REPO_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/REPO_101.mdx'
import CASESTUDY_TEN_YEAR_TREASURY_FUT_OPT from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/CASESTUDY_TEN_YEAR_TREASURY_FUT_OPT.mdx'

import IRS_101 from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/IRS_101.mdx' 
import VOL_CONE from '!babel-loader!@mdx-js/loader!./pages_posts/posts/STIRS/VOL_CONE.mdx' 

import { duration } from "@mui/material";

const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    customBlue: {
      main: '#2196f3',
      contrastText: '#fff',
    }
  },
  typography: {
    subtitle1: {
      fontSize: 12,
    },
    body1: {
      fontWeight: 500,
    },
  },
});

const componentsMap = {
  h1: Heading.h3, // mapping h3 to h1 so we're having a smaller font-size
  h2: Heading.h4,
};

function SubHeading(props) {
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            // mt: 250,
            bgcolor: "#1E1E1E",
            width: 100,
            p: 1,
            // m: 1,
            borderRadius: 1,
            textAlign: "center",
          }}
        >
          <Typography variant="h3" gutterBottom component="div" color="#90caf9">
            {props.title}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

let blogs_chain;
export default function MathApp() {
  const [isDrawerOpen, SetIsDrawerOpen] = useState(false);
  const [userProfile, SetuserProfile] = useState("notbeginner");
  const {
    token,
    handleSignout,
    inputs,
    currentUser,
    setCurrentUser,
    setPaymentFlag,
    paymentFlag,
  } = useContext(firebaseAuth);

  const userIsPremium = usePremiumStatus(currentUser);

  const [currentUsertest, setCurrentUserTest] = useState(null);
  let history = useHistory();

  useEffect(() => {
    if (!window.localStorage.getItem("previousLocation")) {
      window.localStorage.setItem("previousLocation", '/');
    }
  }, []);


  useEffect(() => {
    firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        setPaymentFlag(window.localStorage.getItem("paymentFlag"));
      } else {
        setCurrentUser(null);
      }
    });
    // localStorage.setItem("clicked_buy", false)
  }, []);

  let mathTopicsJSON = [];

  try {
    mathTopicsJSON = require(`./pages_posts`).default();
    blogs_chain = _.chain(mathTopicsJSON)
      // Group the elements of Array based on `color` property
      .groupBy("category")
      // `key` is group's name (color), `value` is the array of objects
      .map((value, key) => ({ category: key, blogs: value }))
      .value();
    console.log(blogs_chain);
  } catch (err) {}

  const handleDrawerClick = () => {
    // SetIsDrawerOpen(false);
    SetIsDrawerOpen((prevCheck) => !prevCheck);
  };

  const handleIsbeginnerClick = (profile) => {
    SetuserProfile(profile);
  };

  const clicked_on = (shape) => {
    alert("You clicked on the " + shape);
  };

  const ggbParameters = {
    // material_id: "",
    // width: "100%",
    // height: "100%",
    borderColor: "#1E1e1E",
    enableShiftDragZoom: false,
    showToolBar: false,
  };



const  ggbParameters2 = {
  width: "1950",
  height: '950',
  enableShiftDragZoom: false,
  showToolBar: false
};


  // Data

  const links = [
    { title: "Google", url: "" },
    { title: "Facebook", url: "" },
    { title: "Twitter", url: "" }
  ];

  const MyDynamicButton = () => {
    const [count, setCount] = useState(0);
    const [show, setShow] = useState(false);
    return (
      <Box textAlign="center">
        <Button
          color={"primary"}
          variant={"contained"}
          size={"large"}
          style={{ margin: "0 auto", display: "flex" }}
          onClick={() => {
            if (show == false) setShow(true);
            if (show == true) setShow(false);
            setCount(count + 1);
          }}
        >
          Click to{" "}
          {show == true ? "Close 3D App" : " view Payoff surface in 3D "}
        </Button>
        {show == true && (
          <GeogebraDraw
            jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/options_intro/journey_treasure.json"
            ggbParameters={ggbParameters}
            appId="appId2"
            email="pavanmirla@gmail.com"
            data={[]}
          />
        )}
      </Box>
    );
  };

  const MyLink = () => {
    const handleClick = () => {
      const newWindow = window.open(
        "http://localhost:3000/landscape",
        "_blank"
      );
      if (newWindow) {
        newWindow.focus();
      }
    };

    return (
      <a href="#" onClick={handleClick}>
        Open Example in new window
      </a>
    );
  };

  const Player = () => {
    const ref = React.createRef();
    return (
      <div>
        <div
          style={{
            position: "fixed",
            top: "55%",
            left: "1%",
            width: "500px",
            height: 500,
          }}
        >
          {/* <h1>Buy</h1> */}
          <Link href="/signin" color="primary">
            {"Google signin"}
          </Link>
          <div>
            <img
              style={{ maxWidth: "100%", maxHeight: "100%", display: "block" }}
              src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/buy_pic.png"
            ></img>
          </div>
        </div>
      </div>
    );
  };

  const GeoOpen = () => {
    const ref = React.createRef();
    return (
      <div>
        <GeogebraDraw
          jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/call_intro/risk_graph_call_1.json"
          ggbParameters={ggbParameters}
          appId="parity_grap_call"
          email="pavanmirla@gmail.com"
          data={[]}
        />
      </div>
    );
  };

  const Video2 = () => (
    <>
      <div
        style={{
          padding: 20,
          backgroundColor: "tomato",
          width: "600px",
          height: "400px",
        }}
      >
        <ReactPlayer
          controls="true"
          width="100%"
          height="100%"
          url="https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_condor/video2_ic_1.mp4"
        />
      </div>
    </>
  );





const ProtectedRoute = ({ component: Component, defaultComponent: DefaultComponent,  ...rest }) => 
{

  let paymentFlag = null;
  const paymentFlagValue = window.localStorage.getItem("paymentFlag");

  if (paymentFlagValue) {
      paymentFlag = JSON.parse(paymentFlagValue);
  }else {
    paymentFlag= false
  }

  window.localStorage.setItem("previousLocation", window.location.pathname);
  const urlKey = window.location.pathname.split("/").pop();
  
return (
  <Route {...rest} render={(props) => (
    paymentFlag === true || urlKey === 'access2023'
      ? <Component {...props} />
      : <DefaultComponent {...props} />
  )} />
)
  }
  

  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <Switch>
          {mathTopicsJSON.map(({ id, Component }) => (
            // <Route key={id} path={"/" + id}>
            //   {<Component />}
            // </Route>
            <ProtectedRoute key={id} path={"/" + id} component={Component} defaultComponent={SignIn} />

          ))}
          <Route exact path="/">
            <AppHeader handleDrawerToggle={handleDrawerClick} />
            {blogs_chain.length > 0 && (
              <>
                {currentUser == null && (
                  <div
                    style={{
                      zIndex: 10000000000,
                      position: "fixed",
                      bottom: "5%",
                      left: "0%",
                      padding: "18px 36px",
                      width: "300px",
                      height: "50px",
                      // border: "3px solid #73AD21",
                      // backgroundColor: "#373737",
                      cursor: "pointer",
                      fontFamily: "Arial",
                      // border: "2px solid gray"
                    }}
                  >
                    <Button
                      style={{
                        // borderRadius: 35,
                        backgroundColor: "#f2c744",
                        padding: "18px 36px",
                        fontSize: "12px",
                        color: "black",
                      }}
                      onClick={(event) => {
                        localStorage.setItem("clicked_buy", true);
                        window.location.href = "/signin";
                      }}
                      variant="contained"
                      color="info"
                    >
                      {"BUY"}
                      <br />
                    </Button>
                  </div>
                )}

                {currentUser != null && (
                  <>
                    {!paymentFlag ? (
                      <>
                        <div
                          style={{
                            zIndex: 10000000000,
                            position: "fixed",
                            top: "30%",
                            left: "40%",
                            padding: "18px 36px",
                            width: "300px",
                            height: "450px",
                            // border: "3px solid #73AD21",
                            backgroundColor: "#373737",
                            cursor: "pointer",
                            fontFamily: "Arial",
                            border: "2px solid gray",
                          }}
                          onClick={() => createCheckoutSession(currentUser.uid)}
                        >
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ color: "gray" }}
                          >
                            Hello, ✋{currentUser.email}
                            <br />
                            <br />
                          </Typography>

                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ color: "white" }}
                          >
                            5 years license for $300
                            <br />
                          </Typography>

                          <br />
                          <Button
                            style={{
                              // borderRadius: 35,
                              backgroundColor: "#f2c744",
                              padding: "18px 36px",
                              fontSize: "12px",
                              color: "black",
                              zIndex: 100000,
                            }}
                            onClick={() => () =>
                              createCheckoutSession(currentUser.uid)}
                            variant="contained"
                            color="info"
                          >
                            {"Make a one time payment "}
                          </Button>
                          <br />
                          <br />

                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ color: "white" }}
                          >
                            Hit the button, wait 5-10 seconds for Stripe magic
                            to happen, complete payment then relogin f
                          </Typography>
                          <br />
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ color: "gray" }}
                          >
                            Having trouble? Payment made already? Contact
                            office@21ifm.com for a quick fix
                          </Typography>
                          <br />

                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ color: "white" }}
                          >
                            Can't click the button? Try this alternative option.
                            <br />
                          </Typography>
                          <a
                            href="https://buy.stripe.com/cN203Ac410XZcYE3cj"
                            target="_blank"
                            style={{ color: "#87CEEB" }}
                          >
                            Buy Now
                          </a>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            zIndex: 10000000000,
                            position: "fixed",
                            display: "hide",
                            bottom: 0,
                            right: 0,
                            width: "200px",
                            height: "60px",
                            // border: "3px solid #73AD21",
                            backgroundColor: "#fecf02",
                            cursor: "pointer",
                          }}
                        >
                          {currentUser.email}
                          🍪 Premium customer!
                        </div>
                      </>
                    )}
                  </>
                )}

                <br />

                {/* <CardsForBlogs
                  in_json={[]}
                  title="VALUABLE INTEREST RATE RISK MANAGEMENT TOOLS"
                  description="Long descption"
                  image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/DATA/agha_mirzae.png"
                /> */}



              

                {!paymentFlag && (
                  <>
                    {/* <CardsForBlogs
                      in_json={[]}
                      title="AN INTERACTIVE, SIMULATIONS BASED  E-LEARNING PLATFORM TO MASTER OPTIONS."
                      description="Long description"
                      image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/homepage/video_linkedin_3.mp4"
                    /> */}

                    <br />

                    {/* <CardsForBlogs
                      in_json={[]}
                      title="INTERACTIVE OPTIONS BOOK REVIEWS"
                      description="Long description"
                      image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/IMF/REVIEWS/25.png,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/IMF/REVIEWS/26.png,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/home/IMF/REVIEWS/27.png"
                    /> */}

                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ButtonMailto
                        label="setup demo"
                        mailto="mailto:office@21ifm.com?subject=I would like to setup a demo!&body=Thanks!"
                      />
                    </Box>
                  </>
                )}

                <br/>
                <br/>
                <br/>

                <br/>
            <br/>
            <br/>

            <br/>
            <br/>
            <br/>

            <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/welcome1.png"/>

   
            <a href="/lock_rates" target="_blank">
              <div style={{ position: 'relative' }}>
                <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/CASESTUDIES/TEN_YEAR_TREASURY_FUT/CIO_LOCK_RATES_1.png" />
              </div>
            </a>

            <br/>
            <br/>
            <br/>

                <br/>
                <br/>
                <br/>
                <Container>
                <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/interest_rates/savings_bonds.jpeg"/>

                </Container>
                
                <CardsForBlogs
                  in_json={blogs_chain[0].blogs}
                  title="COMPOSITION OF BOND MARKETS"
                  description="Long description"
                  image="none"
                />
                

                <CardsForBlogs
                  in_json={blogs_chain[1].blogs}
                  title="INTEREST RATES 101 "
                  description="Long description"
                  image="none"
                />

                <CardsForBlogs
                  in_json={blogs_chain[2].blogs}
                  title="BENCHMARK RATES. TRANSITION TO SOFR FROM LIBOR in 2023"
                  image="none"
                />
                <br/>
                <br/>
                <GeogebraDraw
                  jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/FED_101/FED_QUIZ.json"
                  ggbParameters={ggbParameters2}
                  appId="appId1volquiz"
                  email="pavanmirla@gmail.com"
                  data={[
                  ]}
                /> 
                <br/>
                <br/>
                <CardsForBlogs
                  in_json={blogs_chain[3].blogs}
                  title="FEDERAL RESERVE SYSTEM"
                  description="Long description"
                  image="none"
                />


                  <CardsForBlogs
                  in_json={blogs_chain[4].blogs}
                  title="BONDS PRICING AND INVESTMENT RISKS"
                  description="Long description"
                  image="none"
                />

                  <CardsForBlogs
                  in_json={blogs_chain[5].blogs}
                  title="HEDGING BONDS RISKS WITH FUTURES CONTRACTS"
                  description="Long description"
                  image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/SWAPS/FUTURES_HOME.png"
                 />

                <CardsForBlogs
                  in_json={blogs_chain[6].blogs}
                  title="MANAGING BOND RISK WITH INTEREST SWAPS"
                  description="Long description"
                  image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/SWAPS/CDX_ONLY.png,https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/SWAPS/SWAPS_PEDPX.png"
                 />


                <CardsForBlogs
                  in_json={blogs_chain[7].blogs}
                  title="USING OPTIONS AND FUTURES TO HEDGE BOND RISKS"
                  description="Long description"
                  image="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/SWAPS/PTTRX_CME-PUT.png"
                 />

              <a href="/call_opt_treas_future" target="_blank">
                            <div style={{ position: 'relative' }}>
                              <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/CASESTUDIES/TEN_YEAR_TREASURY_FUT/HOME_TREASURY.png" />
                            </div>
                          </a>


                <CardsForBlogs
                  in_json={blogs_chain[8].blogs}
                  title="ADVANCED TOOLS"
                  description="Long description"
                  image="none"
                 />
          
       <Container>
        <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/about_me.png"/>
       </Container>



             
 {/*

                  <CardsForBlogs
                  in_json={blogs_chain[7].blogs}
                  title="TRADING SHORT TERM INTEREST RATES. SOFR FUTURES and options"
                  description="Long description"
                  image="none"
                 />

                  <CardsForBlogs
                  in_json={blogs_chain[6].blogs}
                  title="LEARNING AND TRADING RESOURCES"
                  description="Long description"
                  image="none"
                 />



                <CardsForBlogs
                  in_json={blogs_chain[8].blogs}
                  title="TRADING SHORT TERM INTEREST RATES. SOFR FUTURES and options"
                  description="Long description"
                  image="none"
                 /> */}
                 {/* <div style={{ position: 'relative' }}>
                    <LoadPdf file="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/home2/libor_sofr.pdf" />
                    <SimpleDialog buttonPosition={{left: "60%", top: "30%"}} title={'My silly title'}>
                      <SOFR/>
                    </SimpleDialog>
                 </div> */}

                 {/* <div style={{ position: 'relative' }}>
                  <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/MDX/libor_sofr.png"/>
                  <SimpleDialog buttonPosition={{left: "14%", top: "10%"}} title={'My silly titlessss. This a long descriptiopn.'} text="A"  list={links}>
                  <div style={{position: 'relative'}}>
                    <LinkList links={links} width="200px" left="50px" top="50px" />
                  </div>
                    <SOFR/>
                  </SimpleDialog>
                  <SimpleDialog buttonPosition={{left: "63%", top: "65%"}} title={'My silly title'}>
                    <SOFR/>
                  </SimpleDialog>
                  <SimpleDialog buttonPosition={{left: "42%", top: "51%"}} title={'My silly title'}>
                    <SOFR/>
                  </SimpleDialog>

                 </div> */}

        


              <div style={{ position: 'relative', display: 'none' }}>
                  <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/MONEY_MARKETS_101/STAIRS_IMG.png"/>

                  <SimpleDialog buttonPosition={{left: "56%", top: "85%"}} title={'SPOT RATES 101'} text="1"  list={links}>
                    <SPOT_RATES_101/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "56%", top: "80%"}} title={'FORWARD RATES 101'} text="2"  list={links}>
                    <FORWARD_RATES_101/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "56%", top: "75%"}} title={'TERM STRUCTURE 101'} text="3"  list={links}>
                    <TERM_STRUCTURE_101/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "50%", top: "71%"}} title={'TIME VALUE OF MONEY 101'} text="4"  list={links}>
                    <TVM_101/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "50%", top: "66%"}} title={'COMPOUNDING AND ANNUALIZATION 101'} text="5"  list={links}>
                    <COMPOUNDING_101/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "36%", top: "64%"}} title={'LIBOR 101'} text="6"  list={links}>
                    <LIBOR_101/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "43%", top: "63%"}} title={'SOFR 101'} text="7"  list={links}>
                    <SOFR/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "48%", top: "62%"}} title={'SOFR TERM 101'} text="8"  list={links}>
                    <SOFR/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "40%", top: "59%"}} title={'FEDERAL RESERVE 101'} text="9"  list={links}>
                    <FED_101/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "35%", top: "56%"}} title={'US TREASURY 101'} text="10"  list={links}>
                    <TREASURY_101/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "42%", top: "51%"}} title={'BONDS PRICING 101'} text="11"  list={links}>
                    <BONDS_101/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "47%", top: "54%"}} title={'BONDS YTM 101'} text="12"  list={links}>
                    <BONDS_101/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "43%", top: "46%"}} title={'MONEY MARKET FUNDS'} text="13"  list={links}>
                    <MONEY_MARKETS_101/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "52%", top: "49%"}} title={'REPO 101'} text="14"  list={links}>
                    <REPO_101/>
                  </SimpleDialog>
                  

                  <SimpleDialog buttonPosition={{left: "50%", top: "43%"}} title={'BOND DURATION'} text="15"  list={links}>
                    <DURATION/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "55%", top: "41%"}} title={'BOND CONVEXITY'} text="16"  list={links}>
                    <CONVEXITY/>
                  </SimpleDialog>


                  <SimpleDialog buttonPosition={{left: "61%", top: "31%"}} title={'FORWARDS AND FUTURES 101'} text="17"  list={links}>
                    <FUTURES_101/>
                  </SimpleDialog>


         

                  <SimpleDialog buttonPosition={{left: "65%", top: "35%"}} title={'OPTIONS 101'} text="18">
                    <OPTIONS_101/>
                  </SimpleDialog>

                  <SimpleDialog buttonPosition={{left: "60%", top: "27%"}} title={'INTEREST RATE FUTURES '} text="19"  list={links}>
                    <INT_RATES_FUTURES_101/>
                  </SimpleDialog>




                  <SimpleDialog buttonPosition={{left: "61%", top: "21%"}} title={'CALL OPTIONS ON FUTURES '} text="20">
                    <INT_RATES_FUTURES_OPTIONS/>
                  </SimpleDialog>

         

                </div>


        

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ButtonMailto
                    label="setup demo"
                    mailto="mailto:office@21ifm.com?subject=I would like to setup a demo!&body=Thanks!"
                  />
                </Box>
              </>
            )}
          </Route>

          <Route path="/taxes">
            <h2>Taxes</h2>
          </Route>

          <Route path="/loans">
            <h2>Loans</h2>
          </Route>

          <Route path="/signup">
            <SignUp />
          </Route>
          <Route path="/register">
            <SignUp />
          </Route>

          <Route path="/signinLink">
            <SignInMagicLink />
          </Route>

          <Route path="/signinemail">
            <SignInEmail />
          </Route>

          <Route path="/payment">
            <Payment />
          </Route>

          <Route path="/wait">
            <MagicLinkWait />
          </Route>

          <Route path="/forgotPassword">
            <ForgotPassword />
          </Route>

          <Route path="/signin">
            <SignIn />
          </Route>

          <Route path="/log">
            <EARTH_QUAKE_LOG />
          </Route>



          <Route path="/purchase">
            <SignInPlusPurchase />
          </Route>

          <Route path="/lock_rates">
            <PHILANTROPHY_SOFR />
          </Route>

          <Route path="/money_markets">
            <MONEY_MARKETS_101 />
          </Route>     

           <Route path="/fed_101">
            <FED_101 />
          </Route>
          
          <Route path="/irs">
            <IRS_101 />
          </Route>


          <Route path="/bonds_101">
            <BONDS_101 />
          </Route>


          <Route path="/bonds_ytm_101">
            <BONDS_101_YTM />
          </Route>


          <Route path="/bonds_risks_101">
            <BONDS_RISKS />
          </Route>


          <Route path="/libor_101">
            <LIBOR_101 />
          </Route>

          <Route path="/int_fut_101">
            <INT_RATES_FUTURES_101 />
          </Route>
          

          <Route path="/opt_int_fut_101">
            <INT_RATES_FUTURES_OPTIONS />
          </Route>
          
          <Route path="/sofr_term">
            <SOFR_TERM_RATE />
          </Route>

             
          <Route path="/funds">
            <BOND_FUNDS />
          </Route>


          {/* <Route path="/call_opt_treas_future">
            <CASESTUDY_TEN_YEAR_TREASURY_FUT_OPT />
          </Route> */}


          <ProtectedRoute key={"id"} path={"/call_opt_treas_future" } component={CASESTUDY_TEN_YEAR_TREASURY_FUT_OPT} defaultComponent={SignIn} />


          <Route path="/__/auth/">
            <SignIn />
          </Route>

          <Route path='*' component={NotFound} />

        </Switch>
      </Router>
    </ThemeProvider>
  );
}

function Home() {
  return (
    <div>
      {/* <AppHeader handleDrawerToggle={handleDrawerClick}/> */}
      <main></main>
    </div>
  );
}
