
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
import ImageZoom from 'react-image-zooom';
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1800,
  height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="EfmTWu2yn5Q" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="HHKwnUa3txo" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: "What is equity collar  strategy? ",
  subtitle: "A equity collar uses options to provide a floor and a ceiling on the future value of the held stock. This involves buying a put at lower strike to secure a minimum value for your portfolio. The cost of purchasing put can be offset by selling a call at higher strike "
}, {
  title: "What is zero-cost collar? ",
  subtitle: "If the strike prices are chosen such that the put and call premium exactly offset each other, then the collar can be setup at zero cost - this is called zero-cost collar"
}];
export const references = [{
  title: "Keith Cuthbertson. Zero-Cost Collar",
  href: "https://www.amazon.ca/Investments-Derivatives-Markets-Keith-Cuthbertson/dp/0471495832"
}, {
  title: "Options for Volatile Markets: Managing Volatility- McMillan ",
  href: "https://www.amazon.ca/Options-Volatile-Markets-Volatility-Catastrophic/dp/1118022262"
}, {
  title: "Mark cuban's Option trade",
  href: "https://www.linkedin.com/pulse/mark-cubans-famous-trade-concentrated-stock-a-altimari-cfp-/"
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const FlipCard = makeShortcode("FlipCard");
const References = makeShortcode("References");
const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
items1,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">










    <h1>{`Equity Collar`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/equity_collar/equity_collar_intro.png" mdxType="ImageCard" />
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/equity_collar/Cuban_collar_story.png" mdxType="ImageCard" />
    </Container>

    {
      /* abc <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/covered_call.mp4"}/>  */
    }
    <Container mdxType="Container">
  <Content items={items1} title={"Learn"} mdxType="Content" />
    </Container>
    <Container mdxType="Container">
  {
        /* <ImageZoom src="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/colar_manual.png" /> */
      }
    </Container>
    <h1>{`INTERACTIVE`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/equity_collar/collar4.json" ggbParameters={ggbParameters} appId="collarid_cahnge" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" />
    <br />
    <br />
    <br />
    <h1>{`QUESTION 1`}</h1>
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/collar_q1_front_1.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/collar_q1_back_1.png" mdxType="FlipCard" />
    <br />
    <br />
    {
      /* https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/covered_call_intro.json */
    }
    {
      /* https://storage.googleapis.com/chapters-ifm/testfolder/2022/covered_call_2022_3.json
      */
    }

    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;