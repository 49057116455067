
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  width: "1950",
  height: '950',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const question1 = {
  text: "What is the capital of France?",
  // image: "https://example.com/france.jpg",
  // video: "https://example.com/france.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const question2 = {
  text: "What is the capital of France?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  // video: "https://example.com/france.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const question3 = {
  text: "What is the capital of France?",
  // image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/short_call_margin_thumbnail.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const topics = [{
  title: 'Efective rates',
  component: () => <div style={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    margin: '20px'
  }}>
          <Container mdxType="Container">
            <Quiz question={question1} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'Nominal vs Real Rates',
  component: () => <div style={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    margin: '20px'
  }}>
          <Container mdxType="Container">
            <Quiz question={question3} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'Compounding frequency',
  component: () => <div style={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    margin: '20px'
  }}>
          <Container mdxType="Container">
            <Quiz question={question2} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'Discount rates',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
        <Container mdxType="Container"> 
            <Quiz question={question2} mdxType="Quiz" />
        </Container>
  </div>
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const VideoSection = makeShortcode("VideoSection");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const ImageCard = makeShortcode("ImageCard");
const Container = makeShortcode("Container");
const Quiz = makeShortcode("Quiz");
const layoutProps = {
  ggbParameters,
ggbParameters2,
opts,
Box1,
Text9,
Text14,
question1,
question2,
question3,
topics
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <br />
    <br />
    <br />
    <h1>{`FEDeral reserve system 101`}</h1>
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/FED_101/FED1.mp4" mdxType="VideoSection" />
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/FED_101/FED2_V1.mp4" mdxType="VideoSection" />
    <br />
    <br />
    <br />
    <h1>{`FED SYSTEM QUIZ`}</h1>
    <br />
    <br />
   <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/FED_101/FED_QUIZ.json" ggbParameters={ggbParameters2} appId="appId1volquizFED" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 
    <br />
    <br />
    <br />
    <br />
    <h1>{`reference`}</h1>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/FED_101/reference_fed.png" mdxType="ImageCard" />




    <h1>{`STORY: USE of Discount window`}</h1>
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/FED_101/DISCOUNT_WINDOW.mp4" mdxType="VideoSection" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;