
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import Link from '@mui/material/Link';
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1900,
  height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Intro = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_straddle/shortstraddle_videos0.mp4' mdxType="ReactPlayer" />
  </div>;
export const Videos10 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_straddle/shortstraddle_videos10.mp4' mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'How to setup Short Straddle?',
  subtitle: 'The short straddle  consists of selling both a put and a call based on the same terms - at-the-money strikes, the same underlying stock, the same strike price, and the same expiration date.'
}, {
  title: 'How much does it cost to setup?',
  subtitle: 'In a short straddle, we are selling options, so initial cash flow is positive - a credit option strategy.'
}, {
  title: 'How do you calculate Max Profit?',
  subtitle: 'The maximum profit from a short straddle position is limited to collected premium.'
}, {
  title: 'How do you calculate Max Loss?',
  subtitle: 'A short straddle has only short options, so at expiration there is assignment risk. Losses are unlimited .'
}, {
  title: 'How do stock price changes affect profitability?',
  subtitle: 'For small stock changes near strike, Short straddle does not change much. Please see the interactive below for more details on why it is a delta-neutral poisiton etc'
}, {
  title: ' What is the impact of time on profitability?',
  subtitle: 'Over time, a short straddle gains value to theta decay. See the interactive below for more information'
}, {
  title: ' What is the impact of volaitlity on profitability?',
  subtitle: 'Option premium increases in value as volatility increases. Therefore, a short straddle incurs losses as volatility rises.'
}];
export const covered_straddle = [{
  title: 'What is a Covered Straddle?',
  subtitle: 'Combining Short straddle with a long stock to cover short call and having cash to cover short put is Covered Straddle'
}, {
  title: 'Two Covered Calls',
  subtitle: 'Covered Straddle payoff looks similar to Covered call payoff'
}, {
  title: 'Two short Puts',
  subtitle: 'Covered Straddle payoff is equivalent to two owning two short puts'
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const UnderLine = makeShortcode("UnderLine");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Intro,
Videos10,
DefaultBox,
Text4,
componetArray,
indexArray,
items1,
covered_straddle
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">












    <h1>{`Short Straddle`}</h1>
    <Container mdxType="Container">
      <p>{`A short straddle is a neutral strategy. It benefits from `}<UnderLine className="thick" mdxType="UnderLine">{` lack of price movement  `}</UnderLine>{`in either direction.`}</p>
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/RATIOS/credit_straddle_new_home.png" mdxType="ImageCard" />
      <br />
      <br />
      <p>{`A short straddle consists of a short put and a short call at the same strike. Typicaly, this strike price is `}<UnderLine className="thick" mdxType="UnderLine">{` at-the-money`}</UnderLine>{`.`}</p>
    </Container>

    <Container mdxType="Container">
  {
        /* <Content items={items1} title={"Learning Objectives"}/> */
      }
    </Container>
    <h1>{`Interactive`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/straddle_credit/spreads_template_straddle_credit_4.json" ggbParameters={ggbParameters} appId="id1shortStraddle" email="pavanmirla@gmail.com" data={[{
      component: <Intro mdxType="Intro" />,
      button: "videos0"
    }, {
      component: <Videos10 mdxType="Videos10" />,
      button: "videos10"
    }]} mdxType="GeogebraDraw" />

    <Container mdxType="Container">
  {
        /* <Content items={covered_straddle} title={"What is Covered Straddle?"}/> */
      }
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;