import  {useState,useEffect,useReducer,useRef} from "react";
import GetGGBJson from "./GetGGBJsonTest";
import Demo from './DraggableDialog';
import { Portal } from "react-portal";
import Draggable from 'react-draggable';


const findIndex = require('lodash').findIndex;
const includes = require('lodash').includes;
const map = require('lodash').map;

export default function GeogebraDraw (props) {
    const [clickItem, setClickItem] = useState('')
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [isOpen, setIsOpen] = useState(false);
    const [index, setIndex] = useState(0);
    let countref = useRef(0)
    let clickRef= useRef('')
    const data = props.data

    function handleClick(d) {

      countref.current++;
      if(includes(map(data, 'button'),d)) {
        // setClickItem(d)
        // forceUpdate()
        setIndex(findIndex(data, (e)=> e.button===d))
        setIsOpen(!isOpen)
      }
      // setIsOpen(!isOpen)
    }
 

  // useEffect(() => {
  //     clickRef.current = clickItem
  //     // alert(clickItem)
  //     if (typeof clickRef.current !== "undefined" && includes(map(data, 'button'),clickRef.current)   ) {
  //     setIndex(findIndex(data, (e)=> e.button===clickRef.current))
  //       setIsOpen(true)
  //       forceUpdate()
  //     } else {
  //       setIsOpen(false)
  //       forceUpdate()
  //    }
  //   },[clickItem]);
  return (
      <>
      {/* <h1 style={{position: 'sticky', top: 20, left: 100, zIndex: 10000}}> This is what I clicked: {clickItem} {countref.current}</h1> */}
      {/* {isOpen && <Demo style={{display:'none'}} open={isOpen} heading={clickItem} email={props.email} component={data[index].component}/>} */}
      {isOpen && <div> <Portal>  
        <Draggable>
        <div className="modal" style={{position: 'fixed', top: 25, float :' right',  left: 10,   zIndex: 1000000, display: "flex"}} >
          <span style={{position: 'absolute',color: "white"}}>This window can be dragged </span>
         {data[index].component} 
         <button  style={{position: 'fixed', top: 5, float :' right', right: 5}} onClick={() => setIsOpen(!isOpen)}>
        {isOpen ? "Close" : "Open "}
      </button>
      </div>
      </Draggable>

       </Portal>
  
      </div>}

      <GetGGBJson
            jsonUrl= {props.jsonUrl}
            ggbParameters={props.ggbParameters}
            appId={props.appId}
            stateChanger={setClickItem}
            onClickHandle = {(d)=> {
              handleClick(d);
            }}
    /> 
    </>
    )
   }
