
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
export const ggbParameters = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 1000
};
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 2000,
  height: 1000
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const question1 = {
  text: "Question: What is the purpose of interest rate futures?",
  // image: "https://example.com/france.jpg",
  // video: "https://example.com/france.mp4",
  answers: ["A) To invest in a particular interest rate", "B) To manage interest rate risk", "C) To speculate on changes in interest rates"],
  correctAnswer: "B) To manage interest rate risk"
};
export const question2 = {
  text: "Question: How are interest rate futures settled?",
  //   image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  // video: "https://example.com/france.mp4",
  answers: ["A) Through physical delivery of the underlying asset", "B) Through cash settlement", "C) Both A and B "],
  correctAnswer: "B) Through cash settlement"
};
export const question3 = {
  text: "Question: How do interest rate futures differ from interest rate options?",
  // image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  //   video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/short_call_margin_thumbnail.mp4",
  answers: ["A) Futures give the holder the right, but not the obligation, to buy or sell an interest rate", "B) Options require the holder to buy or sell an interest rate at a predetermined price", "C) Options give the holder the right, but not the obligation, to buy or sell an interest rate at a predetermined price"],
  correctAnswer: "B) Options require the holder to buy or sell an interest rate at a predetermined price"
};
export const topics = [{
  title: 'PURPOSE',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question1} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'SETTLEMENT',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question2} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'OPTIONS vs FUTURES',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question2} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const VideoSection = makeShortcode("VideoSection");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Box2 = makeShortcode("Box2");
const Container = makeShortcode("Container");
const MyCarousel = makeShortcode("MyCarousel");
const Quiz = makeShortcode("Quiz");
const layoutProps = {
  ggbParameters,
ggbParameters2,
opts,
Box1,
Text9,
Text14,
question1,
question2,
question3,
topics
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <br />
    <br />
    <h1>{`interest rate futures CALL OPTIONS`}</h1>
    <br />
    <br />
    <br />
    <br />
 <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/OPTIONS/CALL_FUTURES_1.mp4" mdxType="VideoSection" /> 
    <br />
    <br />
    <h1>{`interact`}</h1>
    <br />
    <br />
    <br />
    <br />
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/OPTIONS/futures_call_option_long.json" ggbParameters={ggbParameters} appId="appId1volquizOPTIONS" email="pavanmirla@gmail.com" data={[{
      component: <Box2 mdxType="Box2" />,
      button: "longcall"
    }]} mdxType="GeogebraDraw" /> 
    <br />
    <br />
    <br />
    <br />
 <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/OPTIONS/CALL_FUTURES_2.mp4" mdxType="VideoSection" /> 
    <br />
    <br />
    <br />
    <br />
    {
      /* 
      How do interest rate futures differ from interest rate options?
      A) Futures give the holder the right, but not the obligation, to buy or sell an interest rate
      B) Options require the holder to buy or sell an interest rate at a predetermined price
      C) Options give the holder the right, but not the obligation, to buy or sell an interest rate at a predetermined price */
    }




    <Container mdxType="Container">
  <div>
   <MyCarousel topics={topics} mdxType="MyCarousel" />
  </div>
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;