
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 900
};
export const ggbParameters3 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: '100%',
  height: '100%'
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const question1 = {
  text: "What is the capital of France?",
  // image: "https://example.com/france.jpg",
  // video: "https://example.com/france.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const question2 = {
  text: "What is the capital of France?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  // video: "https://example.com/france.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const question3 = {
  text: "What is the capital of France?",
  // image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/short_call_margin_thumbnail.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const topics = [{
  title: 'Treasury Bills',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
             <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/overnight_rate_term_rate.json" ggbParameters={ggbParameters3} appId="appId1volquiz1" email="pavanmirla@gmail.com" data={[{
        component: <Box2 mdxType="Box2" />,
        button: "longcall"
      }]} mdxType="GeogebraDraw" /> 
            {
        /* <Quiz question={question1}/>
        */
      }
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'REPO',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question3} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'FED Funds',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question2} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
          </Container>
  </div>
}, {
  title: 'FED Repo Market',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
       <div className="image-container" style={{
      position: 'absolute',
      top: '50px',
      bottom: '50px',
      left: '50px',
      right: '50px',
      padding: '20px'
    }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/bearish_call_spread/bearish_call_spread_thumb.png" style={{
        maxWidth: '100%',
        maxHeight: '100%'
      }} />
    </div>
      <SimpleDialog buttonPosition={{
      left: "40%",
      top: "40%"
    }} title={'Quiz'} mdxType="SimpleDialog">
            <Container mdxType="Container">
              <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/overnight_rate_term_rate.json" ggbParameters={ggbParameters2} appId="appId1volquiz" email="pavanmirla@gmail.com" data={[{
          component: <Box2 mdxType="Box2" />,
          button: "longcall"
        }]} mdxType="GeogebraDraw" /> 
            </Container>
      </SimpleDialog>
      <ButtonMailto label="Email this" mailto="mailto:office@21ifm.com?subject=Note on SOFR chapter" top={"91%"} left={"88%"} mdxType="ButtonMailto" />
  </div>
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Container = makeShortcode("Container");
const ImageAnnotation = makeShortcode("ImageAnnotation");
const LandmarkButton = makeShortcode("LandmarkButton");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Box2 = makeShortcode("Box2");
const MyCarousel = makeShortcode("MyCarousel");
const Marker = makeShortcode("Marker");
const Quiz = makeShortcode("Quiz");
const SimpleDialog = makeShortcode("SimpleDialog");
const ButtonMailto = makeShortcode("ButtonMailto");
const layoutProps = {
  ggbParameters,
ggbParameters2,
ggbParameters3,
opts,
Box1,
Text9,
Text14,
question1,
question2,
question3,
topics
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">









    <h1>{`SOFR`}</h1>
  <Container mdxType="Container">
  <ImageAnnotation mdxType="ImageAnnotation">
          <>
            <div style={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}>
              <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/SOFR/SOFR_101_1.png" style={{
              width: '100%',
              height: 'auto'
            }} />
              <LandmarkButton id={1} x={35} y={6} text="Secured overnight Financing Rate (SOFR) is a benchmark interest rate that is used to set the terms of financial instruments such as loans, mortgages, and derivatives.
                The SOFR index was introduced by the FED to make it easier for market participants to use SOFR products." mdxType="LandmarkButton" />
              <LandmarkButton id={2} x={85} y={29} text="SOFR is based on the rate at which banks can lend to each other using US Treasuries as collateral. SOFR is denominated in US dollars. " mdxType="LandmarkButton" />
                <LandmarkButton id={85} x={85} y={42} text="SOFR is availble only on an overnight basis. There is a 1 month and 3 month term SOFR based on Futures pricing. SOFR is a secured reference rate. That is lower than LIBOR in theory, because of lower credit risk" mdxType="LandmarkButton" />
               <LandmarkButton id={4} x={25} y={65} text="This charts show the SOFR trend chart since its introduction in  2018. in May 2018, CME launched first SOFR rate futures. " mdxType="LandmarkButton" />
                <LandmarkButton id={5} x={70} y={77} text="The US Federal Reserve reinforced its inflation fight by raising its key interest rate" mdxType="LandmarkButton" />
            </div>
          </>
 </ImageAnnotation>
 </Container>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/overnight_rate_term_rate.json" ggbParameters={ggbParameters2} appId="appId1volquizInt" email="pavanmirla@gmail.com" data={[{
      component: <Box2 mdxType="Box2" />,
      button: "longcall"
    }]} mdxType="GeogebraDraw" /> 
  <Container mdxType="Container">
  <ImageAnnotation mdxType="ImageAnnotation">
          <>
            <div style={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}>
              <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/SOFR/SOFR_101_2.png" style={{
              width: '100%',
              height: 'auto'
            }} />
              <LandmarkButton id={3} x={13} y={8} text="SOFR term structure represents the patternof interest rates on SOFR-linked financial instruments with different maturities. The Term SOFR forward curve represents market-implied
                future setting for 1 month and 3 month term SOFR, index rates commonly used in floating rate commercail real estate and corporate financings" mdxType="LandmarkButton" />
                <LandmarkButton id={3} x={4} y={60} text="CME SOFR term rate is managed by, CME group Benchmark administration and replicates the forward-looking aspects of LIBOR, but with a model algorithm, instead of a panel survey " mdxType="LandmarkButton" />
            </div>
          </>
 </ImageAnnotation>
 </Container>




    <Container mdxType="Container">
  <div>
   <MyCarousel topics={topics} mdxType="MyCarousel" />
  </div>
    </Container>
 <div style={{
      position: 'absolute',
      left: "25%",
      top: '1%'
    }}>
   <Marker left="25%" top="5%" mleft="50%" mtop="25%" text="1" mdxType="Marker">
          <Box1 mdxType="Box1" /> 
        </Marker>
  </div>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;