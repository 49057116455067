import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useHistory } from "react-router-dom";
import { firebaseAuth } from "../provider/AuthProvider";
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import firebase from "firebase";

import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";

// import FoodXLogo from '../assets/FoodXLogo.png';

const useStyles = makeStyles((theme) => ({
  imgWrapper: {
    display: "flex",
    flexGrow: 1
  },
  list: {
    display: "flex",
    flexGrow: 1
  },
  listItem1: {
    marginRight: "0.5em",
    // maxWidth: "25em",
    // border: "2px solid #fff",
    opacity: 0.8,
    textTransform: "uppercase",
    fontWeight: 600
  },

  listItemText: {
    whiteSpace: "nowrap"
    // textAlign: "center"
  },
  appbar: {
    backgroundColor: "black",
    color: "lightgray",
    zIndex: theme.zIndex.drawer + 1,
    minHeight: 20
    // zIndex: 10000
  },
  ifm: {
    backgroundColor: "#ffcf02",
    color: "black",
    maxWidth: "5em",
    textAlign: "center"
  },
  login: {
    maxWidth: "8em",
    textAlign: "center",
    position: "absolute",
    right: 0
  }
}));

function AppHeader(props) {
  const classes = useStyles();
  let history = useHistory();
  const { token, handleSignout, inputs , currentUser} = useContext(firebaseAuth);

  const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [paymentFlag, setPaymentFlag] = useState(false);
    
    useEffect(() => {
        firebase.auth().onAuthStateChanged(user => {
            if (user) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        });
    }, []);
    
    useEffect(() => {
        if (localStorage.getItem('paymentFlag')) {
            setPaymentFlag(true);
        } else {
            setPaymentFlag(false);
        }
    }, []);

  return (
    <AppBar position="fixed" elevation={1} className={classes.appbar}>
      <Toolbar>
         <List className={classes.list}>
          <ListItem  key={"IFM"} className={classes.ifm} component={Link} to={'/'}>
            <ListItemText primary={"IFM"} />
          </ListItem>
          <ListItem button key={"IFMLONG"} className={classes.listItem1} component={Link} to={'/'}>
            <ListItemText primary={"Interactive Mathematics "} />
          </ListItem>

          {isLoggedIn && paymentFlag ? (
         
            <>
            <ListItem
            button
            key={"LOGOUT"}
            className={classes.login}
            onClick={handleSignout}
            >
            <ListItemText primary={"LOGOUT"} />
            </ListItem>
            </>

        ) : (
          <>
       
          <ListItem
            button
            key={"LOGIN"}
            className={classes.login}
            onClick={() => {
              localStorage.setItem("clicked_buy", false)
              history.push("signin")}
            }
            >
            <ListItemText primary={"LOGIN"} />
          </ListItem>


          </>
        )}
        </List>
      </Toolbar>
      {props.children}
    </AppBar>
  );
}

export default withRouter(AppHeader);
