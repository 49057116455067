
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { Carousel } from 'react-responsive-carousel';
export const ggbParameters = {
  width: "50%",
  height: '80%',
  enableShiftDragZoom: false,
  showToolBar: false
};
export const ggbParameters2 = {
  enableShiftDragZoom: false,
  showToolBar: false,
  width: 1900,
  height: 1000
};
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  width: '100%'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Text9 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: '500px',
  height: '500px'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/homevideo.mp4' mdxType="ReactPlayer" />
  </div>;
export const Text14 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/strategies/straddle_intro.mp4' mdxType="ReactPlayer" />
  </div>;
export const question1 = {
  text: "How do investors earn a return on T-bills?",
  // image: "https://example.com/france.jpg",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/MONEY_MARKETS_101/t-bills_discount_basis.mp4",
  answers: ["a) By receiving interest payments periodically", "b) By selling the T-bills at a higher price than the purchase price", "c) By the difference between the discounted purchase price and the face value of the bill"],
  correctAnswer: "c) By the difference between the discounted purchase price and the face value of the bill"
};
export const question2 = {
  text: "How do investors earn a return on T-bills?",
  image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  // video: "https://example.com/france.mp4",
  answers: ["a) By receiving interest payments periodically", "b) By selling the T-bills at a higher price than the purchase price", "c) By the difference between the discounted purchase price and the face value of the bill"],
  correctAnswer: "c) By the difference between the discounted purchase price and the face value of the bill"
};
export const question3 = {
  text: "What is the capital of France?",
  // image: "https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call_option_greeks.gif",
  video: "https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/all_greeks_call/short_call_margin_thumbnail.mp4",
  answers: ["Paris", "London", "Berlin"],
  correctAnswer: "Paris"
};
export const topics = [{
  title: 'Treasury Bills',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
          <Container mdxType="Container">
            <Quiz question={question1} mdxType="Quiz" />
             {
        /* <Quiz question={question2}/> */
      }
                {
        /* <SimpleDialog buttonPosition={{left: "80%", top: "30%"}} title={"T-Bill explainer"} text={"1"}> */
      }
              {
        /* <GeogebraDraw
           jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/interest_rates/discount_factor.json"
           ggbParameters={ggbParameters2}
           appId="appId1volquiz"
           email="pavanmirla@gmail.com"
           data={[
           {component: <Box2/>, button:  "longcall"}
           ]}
         /> 
        </SimpleDialog> */
      }
      </Container>
  </div>
}, {
  title: 'T-BILL Yield Explainer',
  component: () => <div style={{
    overflowY: 'scroll',
    height: '600px',
    maxHeight: '75vh'
  }}>
       <div className="image-container" style={{
      position: 'absolute',
      top: '50px',
      bottom: '50px',
      left: '50px',
      right: '50px',
      padding: '20px'
    }}>
        <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/MONEY_MARKETS_101/tbill-intercative.png" style={{
        maxWidth: '100%',
        maxHeight: '100%'
      }} />
    </div>
      <SimpleDialog buttonPosition={{
      left: "60%",
      top: "70%"
    }} title={"T-Bill explainer"} text={"1"} mdxType="SimpleDialog">
            <Container mdxType="Container">
              <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/interest_rates/discount_factor.json" ggbParameters={ggbParameters2} appId="appId1volquiz" email="pavanmirla@gmail.com" data={[{
          component: <Box2 mdxType="Box2" />,
          button: "longcall"
        }]} mdxType="GeogebraDraw" /> 
            </Container>
      </SimpleDialog>
      <ButtonMailto label="Email this" mailto="mailto:office@21ifm.com?subject=Note on SOFR chapter" top={"91%"} left={"88%"} mdxType="ButtonMailto" />
  </div>
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const VideoSection = makeShortcode("VideoSection");
const GeogebraDraw = makeShortcode("GeogebraDraw");
const Box2 = makeShortcode("Box2");
const Container = makeShortcode("Container");
const ImageAnnotation = makeShortcode("ImageAnnotation");
const LandmarkButton = makeShortcode("LandmarkButton");
const MyCarousel = makeShortcode("MyCarousel");
const Quiz = makeShortcode("Quiz");
const SimpleDialog = makeShortcode("SimpleDialog");
const ButtonMailto = makeShortcode("ButtonMailto");
const layoutProps = {
  ggbParameters,
ggbParameters2,
opts,
Box1,
Text9,
Text14,
question1,
question2,
question3,
topics
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">








    <h1>{`Money Markets 101`}</h1>
    <br />
    <br />
    <br />
    <VideoSection video="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/FED_101/MONEY_MARKET_FUNDS.mp4" mdxType="VideoSection" />
    <br />
    <br />
    <br />
    <br />
    <br />
    <h1>{`Holding period yield`}</h1>
 <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/MONEY_MARKETS_101/HPY_1.json" ggbParameters={ggbParameters2} appId="app1" email="pavanmirla@gmail.com" data={[{
      component: <Box2 mdxType="Box2" />,
      button: "longcall"
    }]} mdxType="GeogebraDraw" /> 
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/interest_rates/discount_factor.json" ggbParameters={ggbParameters2} appId="app2" email="pavanmirla@gmail.com" data={[{
      component: <Box2 mdxType="Box2" />,
      button: "longcall"
    }]} mdxType="GeogebraDraw" /> 
  <Container mdxType="Container">
  <ImageAnnotation mdxType="ImageAnnotation">
          <>
            <div style={{
            position: 'relative',
            width: '100%',
            height: '100%'
          }}>
              <img src="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/MONEY_MARKETS_101/Money_Markets.png" style={{
              width: '100%',
              height: 'auto'
            }} />
              <LandmarkButton id={1} x={14} y={9} text="Money market instruments are financial securities that are used to raise funds in the money market, which is the financial market for borrowing and lending short-term funds. These instruments typically have maturities of less than one year and are considered to be low-risk, low-return investments" mdxType="LandmarkButton" />
              <LandmarkButton id={2} x={35} y={25} text="Short-term debt securities issued by the government with maturities ranging from a few days to 52 weeks" mdxType="LandmarkButton" />
                <LandmarkButton id={3} x={66} y={25} text="Certificates of deposit are Time deposits offered by banks and other financial institutions with maturities ranging from a few days to a year" mdxType="LandmarkButton" />
               <LandmarkButton id={4} x={35} y={40} text="Commercial paper are short-term unsecured promissory notes issued by corporations and financial institutions " mdxType="LandmarkButton" />
                <LandmarkButton id={5} x={66} y={40} text="Repurchase agreements (repos) are short-term loans in whgich a financial institution, sells securities to an investor with an agreement to repurchase them at a later date at a higher price." mdxType="LandmarkButton" />
                <LandmarkButton id={5} x={74} y={56} text="Federal Funds are short-term loans made by banks to other banks with the Federal Reserve as the intermediary" mdxType="LandmarkButton" />
            </div>
          </>
 </ImageAnnotation>
 </Container>




    <Container mdxType="Container">
  <div>
   <MyCarousel topics={topics} mdxType="MyCarousel" />
  </div>
    </Container>
    <h1>{`money market yields`}</h1>
   <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/SOFR/MONEY_MARKETS_101/money_market_yields.json" ggbParameters={ggbParameters2} appId="money_market_yields" email="pavanmirla@gmail.com" mdxType="GeogebraDraw" /> 
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;