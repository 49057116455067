
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
import  { useState, useContext, useEffect } from "react";
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const ggbParameters = {
  // material_id: "",
  width: 1900,
  height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '390',
  width: '510',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="EfmTWu2yn5Q" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'green'
}} />;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="HHKwnUa3txo" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: "What is covered call strategy? ",
  subtitle: "A covered call strategy is long stock + a short call"
}, {
  title: "What is difference betweeen naked call write and covered call write?",
  subtitle: "When a trader sells a call, he is considered covered if he owns 100 shares of the stock. If he does not own shares, the short call is considered to be naked."
}, {
  title: 'Obligation to deliver stock if call is exercised',
  subtitle: "If assigned, the call seller is obliged to sell 100 shares of the stock at the strike price. At expiration, an ITM short call is likely to be assigned (Exercise By Exception by the OCC). Early assignment is unlikely unless it is ITM with no remaining time premium.  "
}, {
  title: 'Profit if option ends up worthless ',
  subtitle: "If the call is OTM at expiration, it will expire worthless, ending the obligation to sell the shares. The call writer's profit is the premium received. "
}, {
  title: 'Closing covered call before expiration',
  subtitle: "Buy to close the short call, removing the obligation to sell the shares. "
}, {
  title: 'Potential profit is limited',
  subtitle: "Upside gain is capped by the short call. It equals the strike price plus the premium received less the cost of the shares."
}, {
  title: 'What happens to net delta after you sell a call while holding a stock?',
  subtitle: "The delta of 100 shares is 100. A call's delta varies from 0 to 100. The delta of a covered call is 100 minus the call's delta."
}, {
  title: 'Volatility of stock',
  subtitle: "Since the strategy will profit if the call is not exercised, less volatile stocks are likely more suitable for writing calls "
}, {
  title: 'Similar to short put. Synthetic equivalent',
  subtitle: "The payoff of covered call is equivalent to a written put (sold put) of same strike and same maturity plus cash/bond equivalent to strike price. This is due to put call parity.  "
}];
export const references = [{
  title: "Source:snideradvisors- Covered call greeks",
  href: ""
}, {
  title: "Source: corporatefinanceinstitute - Vega of short positons",
  href: "https://corporatefinanceinstitute.com/resources/knowledge/trading-investing/vega-%CE%BD/"
}, {
  title: "Fidelity: Covered calls",
  href: "https://www.fidelity.com/learning-center/investment-products/options/anatomy-of-a-covered-call#:~:text=A%20covered%20call%20position%20breaks,%3D%20%2438.40%2C%20commissions%20not%20included."
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const ContentMedia = makeShortcode("ContentMedia");
const FlipCard = makeShortcode("FlipCard");
const References = makeShortcode("References");
const layoutProps = {
  meta,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
items1,
references
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">










    <h1>{`Covered Call`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/covered_call_intro.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>

    {
      /* abc <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/covered_call.mp4"}/>  */
    }
    <Container mdxType="Container">
  <Content items={items1} title={"Getting started"} mdxType="Content" />
    </Container>
  <Container mdxType="Container">
    <ContentMedia title="Click to open App User Guide" mdxType="ContentMedia">
        <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/explore_cc.png" mdxType="ImageCard" />
    </ContentMedia>
    </Container>
    <h1>{`INTERACTIVE`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/covered_call_2022.json" ggbParameters={ggbParameters} appId="cc4_newer_version" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" />
    <br />
    <br />
    <br />
    <h1>{`QUESTIONS`}</h1>
   <Container mdxType="Container">
    <ContentMedia title="Question on covered call delta" mdxType="ContentMedia">
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/delta_qf.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/delta_qb.png" mdxType="FlipCard" />
    </ContentMedia>
    </Container>
   <br />
    <br />
  <Container mdxType="Container">
    <ContentMedia title="Question on covered call theta" mdxType="ContentMedia">
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/theta_qf.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/theta_qb.png" mdxType="FlipCard" />
    </ContentMedia>
    </Container>
  <Container mdxType="Container">
    <ContentMedia title="Question on covered call vega" mdxType="ContentMedia">
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/vega_qf.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/vega_qb_2.png" mdxType="FlipCard" />
    </ContentMedia>
  </Container>
  <Container mdxType="Container">
    <ContentMedia title="Question on strike selection" mdxType="ContentMedia">
    <FlipCard frontimg="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/moneyness_qf.png" backimg="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/moneyness_qb.png" mdxType="FlipCard" />
    </ContentMedia>
  </Container>
    {
      /* https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/covered_call_intro.json */
    }
    <h1>{`Case study`}</h1>
  <>
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/covered_calls/BBBY_CS_LOGO.png
" mdxType="ImageCard" />
      <br />
      <br />
    </>
    {
      /* https://storage.googleapis.com/chapters-ifm/testfolder/2022/covered_call_2022_3.json
      */
    }

    <Container mdxType="Container">
  <References items={references} title={"References"} mdxType="References" />
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;