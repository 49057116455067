
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import LoadGGBLayoutHtml from "../../components/LoadGGBLayoutHtml";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
export const data = [{
  title: "1.Gamma for entire duration of the long call option and a range of stock prices",
  id: 1,
  script: "SetViewDirection[Vector[(90;85°;0°)]]",
  text: "Stock prices are on the x-axis, Time period from start to expiry is on y-axis. z-axis represents Gamma for corresponding x and y."
}, {
  title: "2.Gamma for near-the-money options as time is approaching expiry",
  id: 2,
  script: 'Execute({"SetValue(t,0.02)", "SetValue(N,(5,0.02))"})',
  text: "For options near the money,close to expiration the Delta can have large, sudden moves between close to 0 (OTM) and close to 1 or -1 (ITM)"
}, {
  title: "3.At expiry, What is Gamma Option  is OTM or deep ITM?",
  id: 3,
  script: 'Execute({"SetValue(t,0.02)", "SetValue(N,(2,0.02))"})',
  text: "Gamma goes to 0 when Delta has reached 0 (out-of-the-money) or +1 / -1 (in-the-money) at expiration."
}, {
  title: "4.What happens when implied volatility is high?",
  id: 4,
  script: "SetValue(sgm,1.8)",
  text: "Does Gamma surface flatten or rise?"
}, {
  title: "5.What happens when implied volatility is low?",
  id: 5,
  script: "SetValue(sgm,1)",
  text: "Observe the Gamma surface"
}];
export const items1 = [{
  title: 'What is Gamma?',
  subtitle: "Gamma tells you how much the Delta should change based on a $1 move in the underlying. "
}, {
  title: 'How is Gamma measured?',
  subtitle: 'Gamma is measured in Delta. Not in dollars as other Greeks.If ATM call contract has 50 delta and 10 Gamma, and stock moves up $1 (all other variables held constant), this option will have a 60 Delta'
}, {
  title: 'What is a Delta neutral portfolio?',
  subtitle: 'Portfolio for which delta is zero/ That is it is immune to changes in the underlying stock price.The strategy consists of balancing positive and negative deltas such that the overall delta of all assets is zero.'
}, {
  title: 'Long options have positive Gamma. What does that mean?',
  subtitle: 'This means delta will change in same direction as stock. Long options are bullish strategies.Slope of tangent to the payoff is positive, hence positive delta'
}, {
  title: 'Short options have negative Gamma. What does that mean?',
  subtitle: 'This means delta will change in opposite direction as stock. Short options are bearish strategies.Slope of tangent to the payoff is negative, hence negative delta'
}, {
  title: 'Gamma and price of option',
  subtitle: 'The greater the gamma, the more volatile the price of the option'
}, {
  title: 'Gamma and moneyness',
  subtitle: 'Gamma risk is higher for short options closer to at the money. Deep ITM and far OTM options have lower gamma.'
}, {
  title: 'Gamma over time',
  subtitle: 'Over time, Gamma will decrease for OTM and ITM options and increase drastically for ATM options (due to higher uncertainity ATM)'
}, {
  title: 'Gamma at expiry',
  subtitle: 'High Gamma ATM at expiry, since there is higher uncertainity. Option will be 100 delta or zero delta either direction from ATM. 100 delta equivalent to 100 shares. '
}];
export const ggbParameters = {
  // material_id: "",
  width: 1950,
  height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const opts = {
  height: '90',
  width: '250',
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1
  }
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="-EQO6YMIzqg" opts={opts} mdxType="YouTube" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/videos/gamma_video_drive.mp4' mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => {
  const handleClick = value => {
    alert(value);
  };

  return <div style={{
    padding: 20,
    backgroundColor: '#1e1e1e',
    maxWidth: 150,
    minHeight: 200
  }} onClick={() => handleClick('test')}>
   THIS IS STEP ONE
  </div>;
};
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  minWidth: 300
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const iv_gamma = [{
  title: 'Gamma and implied volatility. When implied volatility decreases',
  subtitle: "When implied volatility decreases, there is less uncertainty in stock movement, and as a result, the delta profile is not flat; it is more recognizable digital profile/closer to a step function. Gamma (rate of change of deltas) is higher when delta profile is close to binary. As a result, when implied volatility is low, ATM calls/puts have a higher Gamma "
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const Text9 = makeShortcode("Text9");
const layoutProps = {
  data,
items1,
ggbParameters,
opts,
Box1,
Box2,
DefaultBox,
Text4,
componetArray,
indexArray,
iv_gamma
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1>{`Gamma`}</h1>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/gamma_3d/gamma_story_white.png
" mdxType="ImageCard" />
    <h1>{`Interactive`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/gamma_3d/gamma_from_storage.json" ggbParameters={ggbParameters} appId="appId1Gammaid1" email="pavanmirla@gmail.com" data={[{
      component: <Text9 mdxType="Text9" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }]} mdxType="GeogebraDraw" /> 
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/gamma_1.png
" mdxType="ImageCard" />
    <br />
    <br />
    <h1>{`Impact of volatility on Gamma`}</h1>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/gamma_2.png
" mdxType="ImageCard" />
    <br />
    <h1>{`Volatility, time and Delta`}</h1>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/gamma_3.png
" mdxType="ImageCard" />
    <br />
    <h1>{`What is Gamma?`}</h1>
    <Container mdxType="Container">
   <>
    <Typography color="#90caf9" variant="body1" gutterBottom mdxType="Typography">
      Gamma represents the rate of change between an option's Delta and the underlying asset's price. 
    </Typography>
    <Typography color="#90caf9" variant="body1" gutterBottom mdxType="Typography">
      Higher Gamma values indicate that the Delta could change dramatically with even very small price changes in the underlying stock or fund.
    </Typography>
      </>
    </Container>

    <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/call_gamma_1.mp4"} mdxType="VideoSection" />
    <Container mdxType="Container">
  <Content items={items1} title={"Basic topics"} mdxType="Content" />
    </Container>








    {
      /* 
      <>
      <div style={{width :"50%", textAlign: "center", margin:"auto", }}>
         <ReactPlayer controls="true" width="100%" height="100%" url={"https://storage.googleapis.com/chapters-ifm/testfolder/videos/delta_gamma_00.mp4"} />
         <StyledBox width="30%">{"A brief intro  video for First Interactive App "}</StyledBox>
      </div>
      </> */
    }
    <>
    </>
    {
      /* <>
      <LoadGGBLayoutHtml data={data} 
      geogebraJSON = {"https://storage.googleapis.com/chapters-ifm/testfolder/d3gamma/gamma3d_delta3d.json"}
      buttonTitle="Click here to load Call Options Intro App"/>
      </> */
    }
    {
      /* 
      <GeogebraDraw 
         jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/d3gamma/gamma3d_delta3d.json"
         ggbParameters={ggbParameters}
         appId="appId2"
         email="pavanmirla@gmail.com"
         data={[
           {component: <Text9/>, button:  "text14"},
           {component: <Text9/>, button:  "text9"}
         ]}
       />  */
    }

    <Container mdxType="Container">
  <Content items={iv_gamma} title={"Gamma and Implied Volaitlity"} mdxType="Content" />
    </Container>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/gamma_2d.json" ggbParameters={ggbParameters} appId="appId2Gamma" email="pavanmirla@gmail.com" data={[{
      component: <Text9 mdxType="Text9" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }]} mdxType="GeogebraDraw" /> 
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;