import React from "react";

import * as posts from "./posts";

export default () => [
  {
    name: "US TREASURY BONDS: SAFE HAVEN",
    id: "treasuries",
    Component: posts.USTREASURIES,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/tresury4.png",
    video :"",
    description:
      "US Treasuries are debt securities issued by the US government to finance its operations, including funding the national debt.",
    active: 1,
    category: "bond_markets",
    level: "1"
  },
  {
    name: "MONEY MARKETS: SHORT-TERM FUNDS",
    id: "money_markets",
    Component: posts.MONEY_MARKETS_101,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/money_markets1.png",
    video :"",
    description:
      "Money markets are financial markets where short-term borrowing and lending of funds take place.",
    active: 1,
    category: "bond_markets",
    level: "2"
  },
  {
    name: "REPO: SECURITIES BUYBACK AGREEMENT",
    id: "repo",
    Component: posts.REPO_101,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/repo1.png",
    video :"",
    description:
      "Repo is a financial transaction in which a dealer or investor sells securities and buys them back at a later date.",
    active: 1,
    category: "bond_markets",
    level: "3"
  },


  {
    name: "UNDERSTANDING SPOT AND FORWARD RATES",
    id: "spot_forward",
    Component: posts.SPOT_RATES_101,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/spot1.png",
    video :"",
    description:
      "Spot rates are current rates, while forward rates predict future rates.Interactive tutorials where every variable is brought to life, empowering you to take charge of your learning process.",
    active: 1,
    category: "bond_basics",
    level: "1"
  },
 

  {
    name: "UNDERSTANDING THE BOND YIELD CURVE",
    id: "bond_yield_curve",
    Component: posts.TERM_STRUCTURE_101,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/curve1.png",
    video :"",
    description:
      "Term structure is bond yield curve, crucial for understanding bond market sentiment, interest rates, and economic expectations.",
    active: 1,
    category: "bond_basics",
    level: "2"
  },
  {
    name: "MONEY TODAY VS. FUTURE: UNDERSTANDING TIME VALUE",
    id: "time_value_of_money",
    Component: posts.TVM_101,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/tvm1.png",
    video :"",
    description:
      "Time value of money refers to the concept that money today is worth more than the same amount in the future due to earning potential.",
    active: 1,
    category: "bond_basics",
    level: "3"
  },
  {
    name: "COMPOUNDING AND ANNUALIZATION",
    id: "compounding",
    Component: posts.COMPOUNDING_101,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/compound1.png",
    video :"",
    description:
      "Annualization of rates is the process of converting rates from shorter periods to an annual basis.",
    active: 1,
    category: "bond_basics",
    level: "4"
  },
  {
    name: "FAREWELL TO LIBOR",
    id: "libor_101",
    Component: posts.LIBOR_101,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/libor1.png",
    video :"",
    description:
      "LIBOR (London Interbank Offered Rate) is a benchmark interest rate that is being phased out by 2023.",
    active: 1,
    category: "benchmarks",
    level: "P"
  },

  {
    name: "SOFR",
    id: "sofr",
    Component: posts.SOFR,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/sofr2.png",
    video :"",
    description:
      "SOFR (Secured Overnight Financing Rate) is a new benchmark interest rate to replace LIBOR.",
    active: 1,
    category: "benchmarks",
    level: "P"
  },
  {
    name: "UNDERSTANDING SOFR TERM RATE",
    id: "sofr_term_rate",
    Component: posts.SOFR_TERM_RATE,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/sofr3.png",
    video :"",
    description:
      "SOFR term rate is a forward-looking benchmark interest rate that is based on SOFR futures contracts.",
    active: 1,
    category: "benchmarks",
    level: "P"
  },
  {
    name: "MONETARY POLICY AND REGULATION: THE FED",
    id: "fed",
    Component: posts.FED_101,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/FED1.png",
    video :"",
    description:
      "The Federal Reserve System (FED) is the central banking system of the United States. It oversees monetary policy and regulates financial institutions.",
    active: 1,
    category: "fed_system",
    level: "P"
  },

  {
    name: "MANAGING FINANCES, ISSUING SECURITIES: US TREASURY",
    id: "treasury",
    Component: posts.TREASURY_101,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/TREASURY1.png",
    video :"",
    description:
      "The US Treasury is the government department responsible for managing federal finances and issuing government securities.",
    active: 1,
    category: "fed_system",
    level: "P"
  },
 
  {
    name: "UNDERSTANDING BOND PRICING BASICS",
    id: "bonds_pricing",
    Component: posts.BONDS_101,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/BONDS_1.png",
    video :"",
    description:
      "Bond pricing refers to the process of determining the theoretical value of a bond based on various factors such as interest rates and credit risk.",
    active: 1,
    category: "bonds_anatomy",
    level: "1.1"
  },
  {
    name: "YIELD TO MATURITY (YTM): UNDERSTANDING BOND RETURNS",
    id: "landscape",
    Component: posts.BONDS_101_YTM,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/YTM.png",
    video :"",
    description:
      "Bond YTM (Yield to Maturity) is the total return anticipated on a bond if held until maturity, and it helps investors compare the potential returns of different bonds.",
    active: 1,
    category: "bonds_anatomy",
    level: "1.2"
  },
  {
    name: "BOND DURATION: INTEREST RATE SENSITIVITY",
    id: "duration",
    Component: posts.DURATION,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/duration_1.png",
    video :"",
    description:
      "Bond duration measures the sensitivity of a bond's price to changes in interest rates, using mathematical intuition and calculus.",
    active: 1,
    category: "bonds_anatomy",
    level: "2.2"
  },
  {
    name: "BOND DURATION: LONGER BOND, HIGHER RATE RISK",
    id: "duration_compare",
    Component: posts.BONDS_102,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/interest_rates/DURATION_WS.png",
    video :"",
    description:
      "As interest rates rise, the price of a bond with a longer duration will fall more than the price of a bond with a shorter duration.",
    active: 1,
    category: "bonds_anatomy",
    level: "2.3"
  },
  {
    name: "MEASURING BOND PRICE CURVATURE: CONVEXITY",
    id: "convexity",
    Component: posts.CONVEXITY,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/convexity_1.png",
    video :"",
    description:
      "Convexity is used to measure the curvature of the relationship between bond prices and interest rates, providing a more accurate estimate of price changes.",
    active: 1,
    category: "bonds_anatomy",
    level: "3"
  },
  {
    name: "BOOTSTRAPPING: SPOT RATES FROM PAR",
    id: "bootstrapping",
    Component: posts.BOOTSTRAPPING,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/interest_rates/bootstrap.png",
    video :"",
    description:
      "Bootstrapping constructs yield curves by calculating spot rates for various maturities, starting with short-term instruments.",
    active: 1,
    category: "bonds_anatomy",
    level: "3"
  },
  {
    name: "LOCKING IN PRICES: FORWARDS AND FUTURES",
    id: "futures_101",
    Component: posts.FUTURES_101,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/futures_1.png",
    video :"",
    description:
      "Forwards and futures are contracts that allow buyers and sellers to lock in prices for an underlying asset, providing price certainty and hedging opportunities.",
    active: 1,
    category: "futures",
    level: "1.1"
  },
  {
    name: "TRADING TREASURY NOTE FUTURES",
    id: "treasury_notes_futures",
    Component: posts.TNOTE_FUTURES,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/treasury3.png",
    video :"",
    description:
      "Treasury note futures are contracts that allow investors to trade standardized US Treasury notes for future delivery.",
    active: 1,
    category: "futures",
    level: "1.2"
  },
  {
    name: "SOFR FUTURES FOR INVESTORS",
    id: "sofr_futures",
    Component: posts.SOFR_FUTURES,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/sofr1.png",
    video :"",
    description:
      "SOFR futures are financial contracts that allow investors to speculate on future movements of the Secured Overnight Financing Rate.",
    active: 1,
    category: "futures",
    level: "2.1"
  },
  {
    name: "CREDIT DEFAULT SWAP: BOND DEFAULT INSURANCE ",
    id: "cdx",
    Component: posts.CDX,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/cds1.png",
    video :"",
    description:
      "CDS or Credit Default Swaps are financial contracts that offer insurance against default risk of bonds..",
    active: 1,
    category: "swaps",
    level: "1"
  },
  {
    name: "INTEREST RATE SWAPS: MANAGING ASSET LIABILITY MISMATCH ",
    id: "irs_locked",
    Component: posts.IRS_101,
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/irs1.png",
    video :"",
    description:
      "An interest rate swap is a financial contract between two parties to exchange interest rate payments.",
    active: 1,
    category: "swaps",
    level: "2"
  },
  {
    name: "OPTIONS ON INTEREST RATE FUTURES",
    id: "call_greeks_short",
    Component: posts.INT_RATES_FUTURES_OPTIONS,
    video: "",
    image: "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/options1.png",
    description:
      "Options on interest rate futures are contracts that provide the right to buy or sell at a predetermined price/time, used for hedging or profiting. ",
    active: 1,
    category: "options_futures",
    level: "1"
  },
  
  {
    name: "LOCK IN RATES WITH INTEREST RATE FUTURES",
    id: "int_rate_futures",
    Component: posts.INT_RATES_FUTURES_101,
    video: "",
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/rates1.png",
    description:
      "Interest rate futures are financial contracts that allow investors to buy or sell fixed-income securities at a future date and at a predetermined interest rate.",
    active: 1,
    category: "options_futures",
    level: "2"
  },

  {
    name: "ADVANCED TOOLS FOR ANALYZING OPTIONS DATA.",
    id: "vol_cone",
    Component: posts.VOL_CONE,
    image:
    "https://storage.googleapis.com/chapters-ifm/testfolder/2023/SOFR/HOME/volcone_2.png",
    video: "",
    description:
      "An In-Depth Exploration of Implied Volatility, Skew, and More",
    active: 1,
    category: "options_tools",
    level: "P"
  },


 
];
function Default() {
  const text = "Hello World . I am Default APP";

  return (
    <div className="App">
      <p> {text} </p>
    </div>
  );
}

function App1() {
  const text = "Hello World . I am inside Pages / App1";

  return (
    <div className="App">
      <p> {text} </p>
    </div>
  );
}
