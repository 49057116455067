
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
import ButtonMailto from "../../components/ButtonMailto.js";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import VideoSection from "../../components/VideoSection";
export const items1 = [{
  title: 'What is Delta for a Long Call? ',
  subtitle: "The delta is the measure of the sensitivity of the option price to movement in underlying assets. Delta value is illustrated in the following app as slope of the tangent to the payoff diagram at a certain price "
}, {
  title: 'Do long and short call deltas differ?',
  subtitle: 'The absolute values remain the same, but the signs differ. Delta for long calls ranges from 0 to 1, while delta for short calls ranges from 0 to -1'
}, {
  title: 'What is a Delta neutral portfolio?',
  subtitle: 'Portfolio for which delta is zero/ That is it is immune to changes in the underlying stock price.The strategy consists of balancing positive and negative deltas such that the overall delta of all assets is zero.'
}, {
  title: 'What is a Delta of deep in the money Calls?',
  subtitle: 'Deep in the money options have a very high delta, meaning they will move almost simultaneously with the underlying asset.'
}];
export const items2 = [{
  title: 'What is sensitivity of Delta to volatility?',
  subtitle: "Uncertainty rises as volatility increases. Stocks can end up in a wide range. Therefore, delta profile spreads wider when volatilities are higher. On the other hand, when volatility is low, it is closer to binary.In step3, observe delta profile. By changing volatility to 1 and lowering to .1, you will observe this. "
}, {
  title: 'What is sensitivity of Delta to time to expiration ?',
  subtitle: ' In-the-money calls with a longer time until expiration always have a lower Delta than the same strike call with a shorter time until expiration'
}];
export const ggbParameters = {
  // material_id: "",
  width: 1950,
  height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};

const layoutProps = {
  items1,
items2,
ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Call Delta`}</h1>

    <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/DELTA/call_delta.mp4"} mdxType="VideoSection" />
    <Container mdxType="Container">
  <Content items={items1} title={"Basics"} mdxType="Content" />
    </Container>
    <h1>{`Long and Short Call Delta`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/COVERED_CALL/delta_long_short_call.json" ggbParameters={ggbParameters} appId="appId2CallLongShort" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" /> 

    <Container mdxType="Container">
  <Content items={items2} title={"Questions to ask"} mdxType="Content" />
    </Container>

  <Box sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: 'center'
    }} mdxType="Box">
              <ButtonMailto label="Write me an E-Mail" mailto="mailto:office@21ifm.com" mdxType="ButtonMailto" />
  </Box>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;